import React, { Component } from 'react'
import { ThemeProvider } from 'styled-components'
import { Element } from 'react-scroll'

import Showcase from '../Showcase'
import themes from '../../styles/theme.json'
import {
  teal,
  yellow,
  orange,
  green,
  purple,
  pink,
} from '../../styles/variables'
import { ensureTrailingSlashes } from '../../../utils'

import showcases from './showcases'
import { Container, Grid, Confetti, Title, Subtitle } from './visual-components'

const CONFETTI_REFRACTORY_PERIOD = 5000

const confettiConfig = {
  angle: 90,
  spread: 200,
  startVelocity: 30,
  elementCount: 60,
  decay: 0.95,
  colors: [teal, yellow, orange, green, purple, pink],
}

class Welcome extends Component {
  state = {
    theme: 'dark',
    canShowConfetti: true,
    willShowConfetti: false,
  }

  componentDidMount() {
    this.onScrollListener = this.triggerConfetti()
    window.addEventListener('scroll', this.onScrollListener, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollListener)
  }

  triggerConfetti = () => {
    if (!(this.title && 'getBoundingClientRect' in this.title)) {
      return
    }

    const { top } = this.title.getBoundingClientRect()
    return () => {
      const midway = top / 2 // roughly halfway up the screen
      const inMiddleRegion =
        window.pageYOffset <= midway + 100 && window.pageYOffset >= midway - 100
      const onMobile = window.innerWidth <= 768
      const { canShowConfetti, willShowConfetti } = this.state
      if (canShowConfetti && inMiddleRegion && !onMobile && !willShowConfetti) {
        this.setState({
          willShowConfetti: true,
          canShowConfetti: false,
        })
        setTimeout(
          () => this.setState({ canShowConfetti: true }),
          CONFETTI_REFRACTORY_PERIOD
        )
      } else if (!inMiddleRegion && willShowConfetti) {
        this.setState({ willShowConfetti: false })
      }
    }
  }

  render() {
    const { theme, willShowConfetti } = this.state

    return (
      <ThemeProvider theme={themes[theme]}>
        <Container>
          <Grid>
            <Confetti config={confettiConfig} active={willShowConfetti} />
            <Title
              innerRef={(elem) => {
                this.title = elem
              }}
            >
              Welcome to Typeform’s APIs!
            </Title>
            <Subtitle>
              With our APIs and developer tools, you can do everything from the
              basics, like tweak your themes and add new images to your account,
              to the most powerful features Typeform offers, like retrieve
              responses on demand and customize forms with Hidden Fields. And
              you can do it all without using the Typeform builder or even
              logging into Typeform.com!
            </Subtitle>
          </Grid>
          <Grid>
            {showcases.map(({ color, name, title, desc, sample, icon }) => (
              <Element name={name} key={name}>
                <Showcase
                  link={ensureTrailingSlashes(name)}
                  color={color}
                  title={title}
                  description={desc}
                  icon={icon}
                  code={require(`./${sample}`)}
                  key={name}
                />
              </Element>
            ))}
          </Grid>
        </Container>
      </ThemeProvider>
    )
  }
}

export default Welcome
