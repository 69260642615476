import React, { Component, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import Cui from 'react-cui'

import Header from '../Header'
import Layout from '../Layout'
import Sidebar from '../Sidebar'
import Content from '../Content'
import themes from '../styles/theme.json'
import { productColors, darkGrey } from '../styles/variables'
import Footer from '../Footer'

import { SidebarWrap, ContentWrap, PageContainer } from './visual-components'

class Page extends Component {
  state = {
    isSidebarOpen: false,
    theme: 'light',
  }

  toggleSidebar = () => {
    this.setState(
      {
        isSidebarOpen: !this.state.isSidebarOpen,
      },
      () => {
        if (this.state.isSidebarOpen) {
          window.addEventListener('touchmove', this.blockScrolling)
        } else {
          window.removeEventListener('touchmove', this.blockScrolling)
        }
      }
    )
  }

  blockScrolling = (ev) => {
    if (!this.sidebar.includes(ev.target)) {
      ev.preventDefault()
    }
    if (!this.state.isSidebarOpen) {
      window.removeEventListener('touchmove', this.blockScrolling)
    }
  }

  render() {
    const { children, category, location } = this.props
    const color = productColors[category] || darkGrey
    const theme = { color, ...themes[this.state.theme] }

    return (
      <ThemeProvider theme={theme}>
        <div>
          <Layout
            isSidebarOpen={this.state.isSidebarOpen}
            closeSidebar={this.toggleSidebar}
          >
            <Header onSidebarClick={this.toggleSidebar} isDocPage />
            <PageContainer>
              <SidebarWrap
                innerRef={(elem) => (this.sidebar = elem)}
                isHeaderPinned={this.state.headerPinned}
              >
                <Sidebar
                  isHeaderPinned={this.state.headerPinned}
                  currentPath={location.pathname}
                />
              </SidebarWrap>
              <ContentWrap>
                <Content centeredLayout>
                  {cloneElement(children, { theme })}
                  <Cui
                    uid="ARjL6E"
                    theme={theme.color}
                    avatar="https://labs-assets.typeform.com/cui/avatars/ARjL6E/168c744a24e5d6698d12.png"
                    height="300"
                  />
                </Content>
              </ContentWrap>
            </PageContainer>
            <Footer />
          </Layout>
        </div>
      </ThemeProvider>
    )
  }
}

Page.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default Page
