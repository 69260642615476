import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={100} height={100} viewBox="0 0 100 100" {...props}>
      <g strokeWidth={6} stroke="#E4BA3F" fill="none" fillRule="evenodd">
        <rect x={3} y={3} width={94} height={94} rx={7} />
        <path d="M73 32H56.699l-4.484 7.958H28v28.04h44.558z" />
      </g>
    </svg>
  )
}

export default SvgComponent
