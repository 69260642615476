import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'instagram'}</title>
      <defs>
        <linearGradient
          x1="97.763%"
          y1="12.932%"
          x2="16.562%"
          y2="100%"
          id="prefix__a"
        >
          <stop stopColor="#4D61D3" offset="0%" />
          <stop stopColor="#6E52C4" offset="22.999%" />
          <stop stopColor="#CB318A" offset="47.881%" />
          <stop stopColor="#F7722F" offset="73.852%" />
          <stop stopColor="#FED677" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M11 12.817a1.817 1.817 0 110-3.634 1.817 1.817 0 010 3.634zM11 8.2a2.8 2.8 0 110 5.6 2.8 2.8 0 010-5.6zm3.818.255a.764.764 0 11-1.528 0 .764.764 0 011.528 0zM11 5.655c1.452 0 1.634.006 2.204.032.569.026.957.116 1.297.248.351.137.65.319.947.616.297.297.48.595.616.947.132.34.222.729.248 1.297.026.57.032.752.032 2.204 0 1.452-.006 1.634-.032 2.204-.026.569-.116.957-.248 1.297a2.63 2.63 0 01-.616.947c-.297.297-.595.48-.947.616-.34.132-.729.222-1.297.248-.57.026-.752.032-2.204.032-1.452 0-1.634-.006-2.204-.032-.569-.026-.957-.116-1.297-.248a2.63 2.63 0 01-.947-.616 2.608 2.608 0 01-.616-.947c-.132-.34-.222-.729-.248-1.297-.026-.57-.032-.752-.032-2.204 0-1.452.006-1.634.032-2.204.026-.569.116-.957.248-1.297a2.63 2.63 0 01.616-.947c.297-.297.595-.48.947-.616.34-.132.729-.222 1.297-.248.57-.026.752-.032 2.204-.032zm0 .963c-1.427 0-1.596.005-2.16.031-.521.024-.804.111-.992.184a1.663 1.663 0 00-.615.4 1.655 1.655 0 00-.4.615c-.073.188-.16.471-.184.992-.026.564-.031.733-.031 2.16s.005 1.596.031 2.16c.024.521.111.804.184.992.097.249.213.428.4.615.187.187.365.303.615.4.188.073.471.16.992.184.564.026.733.031 2.16.031s1.596-.005 2.16-.031c.521-.024.804-.111.992-.184.249-.097.428-.213.615-.4.187-.187.303-.365.4-.615.073-.188.16-.471.184-.992.026-.564.031-.733.031-2.16s-.005-1.596-.031-2.16c-.024-.521-.111-.804-.184-.992a1.663 1.663 0 00-.4-.615 1.655 1.655 0 00-.615-.4c-.188-.073-.471-.16-.992-.184-.564-.026-.733-.031-2.16-.031zM11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281z"
        fill="url(#prefix__a)"
      />
    </svg>
  )
}

export default SvgComponent
