module.exports = `import { createWidget } from '@typeform/embed'

createWidget('<form-id>',
  {
    hideHeaders: true,
    hideFooter: true,
    opacity: 75,
    hidden: {
        email: 'user@example.com'
    }
  }
)`
