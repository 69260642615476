import React from 'react'
import styled from 'styled-components'

import Popover from '../Popover'
import { productColors, monospace } from '../styles/variables'
import { Icon, product } from '../icons'
import { styliseCategory } from '../../utils'

const CategoriesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
`

const Category = styled.li`
  display: inline-block;
  margin-right: 10px;
`

const CategoryPopoverContent = styled.div`
  color: ${(props) => props.color};
  font-family: ${monospace};
  font-size: 0.875rem;
  font-weight: bold;
  padding: 10px;
`

const Categories = ({ categories }) => {
  if (!categories) {
    return null
  }

  return (
    <CategoriesList>
      {categories.map((category) => (
        <Category key={category}>
          <Popover
            content={
              <CategoryPopoverContent color={productColors[category]}>
                {styliseCategory(category)}
              </CategoryPopoverContent>
            }
          >
            <Icon size={28}>{product[category]}</Icon>
          </Popover>
        </Category>
      ))}
    </CategoriesList>
  )
}

export default Categories
