import React, { Component } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import Header from '../components/Header'
import Layout from '../components/Layout'
import { media } from '../components/styles/media'
import { monospace } from '../components/styles/variables'
import space from '../components/images/space.png'

const Button = styled(Link)`
  display: inline-block;
  margin: 1rem;
  font-family: ${monospace};
  font-weight: bold;
  vertical-align: middle;
  width: 12.5rem;
  background: #fff;
  border: 1px solid;
  border-radius: 2px;
  padding: 0.75rem 1rem;
  box-shadow: 0 10px 30px -10px currentColor;
  transition: box-shadow 0.2s ease, transform 0.2s ease;

  color: ${(props) => props.color};
  border-color: ${(props) => props.borderColor};

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 12px 50px -10px currentColor;
    text-decoration: none;
  }

  ${media.phone`
    width: 11rem;
    padding: .75rem .25rem;
    font-size: 0.9375rem;
    margin: 0.375rem;
  `};
`

const PageErrorWrap = styled.div`
  height: 100%;
  min-height: calc(100vh - 87px);
  max-width: 70rem;
  height: 100%;
  margin: 0 auto;
  align-self: center;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  padding: 5rem 2rem 5rem 50%;
  margin: 0 auto;
  background: url(${space}) no-repeat;
  background-size: contain;

  ${media.tabletLandscape`
    padding: 7rem 2rem 7rem 60%;
  `} ${media.tablet`
    width: 100%;
    padding: 67% 1rem 1rem 1rem;
    margin: 3rem 9rem;
  `} ${media.phone`
    margin: 0rem 4rem 2rem;
  `};
`

const Title = styled.h2`
  margin: 0;
  font-size: 8.25rem;
  line-height: 1;

  ${media.phone`
     font-size: 4.6875rem;
  `};
`

const Text = styled.p`
  margin: 0 0 2.5rem;
  font-size: 1.5rem;
  font-weight: 600;

  ${media.phone`
     font-size: 1.25rem;
     margin-bottom: 1.5rem;
  `};
`

class NotFoundPage extends Component {
  state = {
    headerPinned: false,
  }

  render() {
    const { pathname: currentPath } = this.props.location

    return (
      <div>
        <Helmet title="Typeform's Developer Platform" />
        <Layout>
          <Header currentPath={currentPath} />
          <PageErrorWrap>
            <Container>
              <Title>404</Title>
              <Text>Page Not Found</Text>
              <Button to="/" color="#72bed8" borderColor="#c3e1e5">
                Go to Home Page
              </Button>
            </Container>
          </PageErrorWrap>
        </Layout>
      </div>
    )
  }
}

export default NotFoundPage
