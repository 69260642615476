// transition timing functions
const cubic = 'cubic-bezier(0.94,.06,.32,.95)'

// fonts
const defaultFontFamily = `'apercu-pro',  -apple-system, BlinkMacSystemFont, Helvetica Neue, sans-serif`
const monospace = `'apercu-mono-pro', 'Roboto Mono', monospace`

// cdn
const cdnURL = 'https://d31kvrskfu54aq.cloudfront.net'

// colors
const charcoal = '#4a4a4a'
const darkGrey = '#37404a'
const pink = '#c75875'
const lightGrey = '#aaa'
const teal = '#72bec8'
const green = '#89bc62'
const yellow = '#e4ba3f'
const purple = '#c588c7'
const orange = '#cb732b'
const rose = '#ff6d6d'
const arsenic = '#37404a'

const productColors = {
  getstarted: teal,
  create: green,
  responses: yellow,
  webhooks: purple,
  embed: orange,
  default: darkGrey,
}

module.exports = {
  maxWidth: '60rem',
  gridWidth: '920px',
  cubic,
  cdnURL,
  colorPrimaryDark: darkGrey,
  defaultFontFamily,
  monospace,
  productColors,
  charcoal,
  darkGrey,
  pink,
  lightGrey,
  teal,
  green,
  yellow,
  purple,
  orange,
  rose,
  arsenic,
}
