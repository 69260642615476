import React from 'react'
import { Footer } from '@typeform/ginger'

const pageLocales = [
  {
    title: 'en',
    href: '/',
  },
]

const DefaultFooter = () => {
  return <Footer pageLocales={pageLocales} />
}

export default DefaultFooter
