import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'snapchat'}</title>
      <path
        d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281zm-.056-4.679h-.13c-.63 0-.963-.185-1.352-.463-.278-.185-.537-.37-.833-.426-.167-.019-.315-.037-.463-.037a2.49 2.49 0 00-.611.074l-.222.037a.168.168 0 01-.167-.111l-.056-.241c-.037-.204-.074-.333-.167-.352-1.019-.148-1.296-.37-1.37-.519v-.074c0-.056.037-.111.093-.111 1.556-.259 2.259-1.852 2.278-1.907.093-.185.111-.352.056-.5-.111-.259-.463-.37-.685-.444l-.148-.056c-.463-.185-.5-.37-.481-.463.037-.167.241-.278.426-.278l.13.019c.204.093.389.148.556.148.222 0 .315-.093.333-.111l-.019-.333c-.056-.741-.111-1.648.13-2.167.685-1.556 2.167-1.685 2.611-1.685h.204c.444 0 1.926.13 2.63 1.685.241.519.167 1.444.13 2.167v.037l-.019.296c.019.019.093.093.296.111.148 0 .333-.056.519-.148l.167-.037.185.037c.148.056.259.167.259.278 0 .111-.074.278-.481.426l-.148.056c-.222.074-.574.185-.685.444-.056.13-.037.296.056.5.037.074.685 1.667 2.241 1.926.056.019.093.056.093.111l-.019.074c-.056.148-.315.352-1.333.519-.074.019-.111.111-.167.352l-.056.241c-.019.074-.074.111-.148.111h-.019l-.222-.037a2.865 2.865 0 00-.611-.056c-.148 0-.296.019-.444.037-.296.056-.556.241-.833.426-.37.278-.87.444-1.5.444"
        fill="#FFF900"
      />
    </svg>
  )
}

export default SvgComponent
