import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import Button from '@typeform/ginger/dist/components/button'
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '@typeform/ginger'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { Colors } from '@typeform/ginger/dist/constants/colors'
import { media } from '@typeform/ginger/dist/util/media'

import {
  bounce,
  closeButtonAppear,
  menuAppear,
  menuListItemAppear,
  mobileMenuAppear,
} from './keyframes'

const { largeScreen, extraLargeScreen } = media

export const Wrap = styled.header`
  position: ${(props) => (props.fixed ? `fixed` : `relative`)};
  width: 100%;
  height: ${(props) =>
    props.compact ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT}px;
  font-family: ${(props) => props.fontFamily};
  font-size: 16px;
  top: 0;
  left: 0;
  background-color: #fff;
  color: ${(props) =>
    props.palette === Palette.Positive ? Colors.Ink : '#fff'};
  z-index: 1001;
  opacity: 1;
  -webkit-backface-visibility: hidden;
  visibility: visible;
  transition: transform 0.4s cubic-bezier(0.94, 0.06, 0.32, 0.95),
    background 0.2s ease, color 0.2s ease;
  box-shadow: 0 1px 6px rgba(38, 38, 39, 0.05);
`

export const Inner = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 32px 24px;
  ${largeScreen`
    padding: 0 24px;
    background: #fff;
  `}
`

export const LogoLink = styled(GatsbyLink)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.div`
  background-image: url('https://public-assets.typeform.com/public/logo/wordmark_black.svg');
  background-size: 72px 16px;
  background-repeat: no-repeat;
  background-position: center;
  height: 17px;
  width: 82px;
  display: inline-block;
  border-right: 1px solid black;
`

export const Title = styled.span`
  margin-left: 4px;
`

export const Spacer = styled.div`
  margin: 0 auto;
`

export const Nav = styled.nav`
  display: flex;
  align-items: center;
`

export const Menu = styled.div`
  position: relative;
  padding: 0;
  margin-right: 32px;
  ${largeScreen`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-right: 0;
  `}
`

export const LinkWrap = styled.div`
  padding: 0 1px;
  margin: 0 -1px;
  border-bottom: 1px solid ${(props) => !props.isActive && 'transparent'};
  a {
    font-size: inherit;
    color: inherit;
  }
  a:visited {
    color: inherit;
  }
  ${largeScreen`
  border-bottom: 0;
  `}
`

export const Link = styled.a`
  font-size: inherit;
  color: inherit;
`

export const Submenu = styled.ul`
  display: none;
  position: absolute;
  top: 70px;
  left: -32px;
  min-width: 256px;
  padding: 16px;
  font-size: 14px;
  list-style: none;
  background: #fff;
  color: ${Colors.Ink};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07), 0 4px 12px 0 rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  transform-origin: top center;
  animation: ${menuAppear} 0.15s ease;
  animation-fill-mode: both;
  ${Link} {
    font-size: 14px !important;
  }
  li {
    margin-bottom: 8px;
    padding: 8px 16px;
    border-radius: 2px;
    transition: background 0.25s ease;
  }
  li:hover {
    background: #f1ece3;
  }
  li:last-child {
    margin-bottom: 0;
  }
`

export const Item = styled.li`
  text-align: center;
  font-size: inherit;
  padding: 24px 0;
  user-select: none;
  > a {
    color: inherit;
  }
  ${extraLargeScreen`
  :hover ${Submenu} {
    display: block;
  }
  `}
  ${largeScreen`
  font-weight: 300;
  padding: 8px 0;
  animation: ${menuListItemAppear} 0.5s ease;
  animation-fill-mode: both;
  transition: color 0.15s ease;
  ${[1, 2, 3, 4, 5].map(
    (n) => css`
      &:nth-child(${n}) {
        animation-delay: ${0.3 + n * 0.1}s;
      }
    `
  )}
  &:hover {
    color: rgba(255, 255, 255, 0.75);
    .main-header__submenu {
      display: none;
    }
  }
  `}
`

export const MenuButton = styled.button`
  -webkit-appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0 0 0 24px;
  outline: none;
  ${extraLargeScreen`
    display: none;
  `}
`

export const MenuList = styled.ul`
  display: flex;
  padding: 0;
  color: ${(props) =>
    props.palette === Palette.Positive ? Colors.Ink : '#fff'};
  list-style: none;
  & > :not(:last-child) {
    margin-right: 24px;
  }
  ${largeScreen`
  width: 100%;
  height: 100%;
  margin: 0;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  font-size: 40px;
  line-height: 48px;
  background: ${Colors.Ink};
  color: white;
  animation: ${mobileMenuAppear} .6s ease;
  animation-fill-mode: both;
  `}
`

export const CloseButton = styled.button`
  ${extraLargeScreen`
  display: none;
  `}
  position: fixed;
  top: 0;
  right: 4px;
  font-size: 64px;
  font-weight: 100;
  -webkit-appearance: none;
  background: none;
  border: none;
  animation: ${closeButtonAppear} 0.5s ${bounce};
  animation-fill-mode: both;
  animation-delay: 0.5s;
  color: #fff;
  padding: 0 16px;
  line-height: 0.92;
  margin: 0;
  outline: none;
  user-select: none;
`

export const Hair = styled.svg`
  ${extraLargeScreen`
  display: none;
  `}
  position: absolute;
  z-index: 2;
  pointer-events: none;
  touch-action: none;
  ${['top', 'left', 'right', 'bottom'].map(
    (pos) =>
      css`
        ${pos}: ${(props) => props[pos]};
      `
  )}
`

export const SignupButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`

// saved as a custom component with a media query
// to allow proper displaying with SSR
export const SignupButton = styled(Button)`
  ${largeScreen`
    background: ${Colors.Ink};
    color: #fff;
  `}
`

export const MenuFooterWrapper = styled.div`
  ${extraLargeScreen`
    display: none;
  `}
  position: absolute;
  padding: 24px;
  bottom: 24px;
  z-index: 10;
  animation: ${mobileMenuAppear} 1s ease;
  animation-fill-mode: both;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`
