import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'reddit'}</title>
      <g fill="#FF5D01">
        <path d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281zm5.992-10.31c0-.858-.674-1.554-1.506-1.554-.417 0-.793.175-1.065.457-.842-.56-1.918-.918-3.1-.973.001-.518.225-1 .617-1.324a1.603 1.603 0 011.389-.334l.067.015-.003.052c0 .716.563 1.297 1.257 1.297s1.257-.581 1.257-1.297-.563-1.297-1.257-1.297a1.25 1.25 0 00-1.061.603l-.12-.028a2.236 2.236 0 00-1.937.465 2.39 2.39 0 00-.86 1.847c-1.181.056-2.257.413-3.099.973a1.478 1.478 0 00-1.066-.457c-.832 0-1.506.696-1.506 1.554 0 .598.328 1.116.808 1.376a3.195 3.195 0 00-.056.589c0 2.233 2.348 4.043 5.244 4.043s5.244-1.81 5.244-4.043c0-.2-.019-.397-.056-.589.48-.26.808-.778.808-1.376zm-2.343-4.287c.335 0 .607.281.607.627s-.272.627-.607.627c-.335 0-.607-.281-.607-.627s.272-.627.607-.627zM5.65 9.971c0-.487.384-.883.856-.883a.83.83 0 01.534.195 3.922 3.922 0 00-1.033 1.405.89.89 0 01-.358-.717zm8.665 4.287c-.879.678-2.058 1.051-3.319 1.051-1.261 0-2.44-.373-3.319-1.051-.822-.634-1.275-1.458-1.275-2.322 0-.863.453-1.688 1.275-2.322.879-.678 2.058-1.051 3.319-1.051 1.261 0 2.44.373 3.319 1.051.822.634 1.275 1.458 1.275 2.322 0 .863-.453 1.688-1.275 2.322zm1.669-3.57c-.22-.523-.575-1-1.033-1.405a.83.83 0 01.534-.194c.472 0 .856.396.856.883a.89.89 0 01-.358.716z" />
        <g transform="translate(8.377 10.27)">
          <circle cx={0.704} cy={0.722} r={1} />
          <circle cx={4.534} cy={0.722} r={1} />
        </g>
        <path d="M12.621 12.711a2.56 2.56 0 01-3.681-.155c-.122-.135-.328-.143-.459-.017s-.138.338-.016.474a3.238 3.238 0 002.392 1.074c.817 0 1.599-.312 2.202-.88a.343.343 0 00.021-.474.318.318 0 00-.459-.022z" />
      </g>
    </svg>
  )
}

export default SvgComponent
