import React, { useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Prism from 'prismjs'
import { ThemeProvider } from 'styled-components'

import CopyButton from './CopyButton'
import ExpandIcon from './expand'
import {
  PreWrap,
  Pre,
  Toolkit,
  ExpandButton,
  ShowAll,
} from './CodeBlock.visual-components'

import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-json'

const MAX_LINES_BEFORE_TRUNCATION = 10

const getLanguage = (language) => {
  switch (language) {
    case 'curl':
      return 'bash'
    case 'hugo':
    case 'shortcode':
      return 'markup'
    default:
      return language in Prism.languages ? language : 'markup'
  }
}

const highlightSyntax = (content, lang) => {
  return Prism.highlight(content, Prism.languages[lang])
}

const CodeBlock = ({ children, lang: langProp, theme }) => {
  const [expanded, setExpanded] = useState(false)
  const innerRef = useRef(null)
  const innerHeightRef = useRef(0)

  const toggleExpansion = () => {
    setExpanded((previousExpanded) => !previousExpanded)
  }

  useLayoutEffect(() => {
    if (innerRef.current) {
      innerHeightRef.current = innerRef.current.offsetHeight || 0
    }
  })

  const getPropsFromChild = () => {
    if (typeof children === 'string') {
      return {
        content: children,
        lang: langProp || 'markup',
        lines: children.split('\n').length,
      }
    } else if (
      children.length &&
      children[0].props &&
      children[0].props.children.length
    ) {
      const childProps = children[0].props
      const content = childProps.children[0].trim()
      return {
        content,
        lang: getLanguage(childProps['data-language'] || 'bash'),
        lines: content.split('\n').length,
      }
    }
  }

  const childInfo = getPropsFromChild()

  if (!childInfo) {
    // eslint-disable-next-line
    console.warn('Invalid children passed to CodeBlock', children)
  }

  const { content, lang, lines } = childInfo
  const highlighted = highlightSyntax(content, lang)
  const className = `language-${lang} theme-${theme ? theme.name : 'light'}`
  const expandable = lines > MAX_LINES_BEFORE_TRUNCATION

  return (
    <ThemeProvider theme={theme}>
      <PreWrap
        expanded={expanded}
        naturalHeight={innerHeightRef.current}
        expandable={expandable}
      >
        <Pre className={className} ref={innerRef}>
          <code
            className={className}
            dangerouslySetInnerHTML={{ __html: highlighted }}
          />
        </Pre>
        <Toolkit>
          {expandable && (
            <ExpandButton onClick={toggleExpansion} expanded={expanded}>
              <ExpandIcon />
            </ExpandButton>
          )}
          <CopyButton snippet={content} />
        </Toolkit>
        {expandable && (
          <ShowAll onClick={toggleExpansion} expanded={expanded}>
            {expanded ? 'Show less' : `${lines} lines`}
          </ShowAll>
        )}
      </PreWrap>
    </ThemeProvider>
  )
}

CodeBlock.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  lang: PropTypes.string,
}
CodeBlock.defaultProps = {
  lang: 'markup',
}

export default CodeBlock
