import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media } from '../styles/media'

import { convertChildrenIntoId } from './utils'
import ClipIcon from './clip'

const Wrapper = styled.h2`
  position: relative;
  margin-bottom: 0.5rem;
  padding: 1.8rem 0 0;
  border-top: ${(props) => (props.border ? '1' : '0')}px solid
    ${(props) => props.theme.heading.color};
  transition: border-top 0.25s ease;
`

const Title = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 1.25rem;
`

const ClipWrap = styled.span`
  position: absolute;
  left: -2rem;
  cursor: pointer;

  path {
    opacity: 0.5;
    fill: ${(props) => props.color};
    transition: fill 0.25s ease;
  }

  :hover path {
    opacity: 1;
  }

  ${media.tablet`
    display: none;
  `};
`

const Clip = ({ color, id }) => (
  <ClipWrap color={color}>
    <a href={`#${id}`}>
      <ClipIcon />
    </a>
  </ClipWrap>
)

Clip.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string.isRequired,
}

export const H2 = ({
  color,
  anchor,
  children,
  theme,
  border = true,
  ...props
}) => {
  const id = convertChildrenIntoId(anchor || children)
  if (id) {
    return (
      <span id={id} {...props}>
        <Wrapper theme={theme} border={border}>
          <Clip id={id} color={color} />
          <Title>{children}</Title>
        </Wrapper>
      </span>
    )
  }

  return (
    <Wrapper theme={theme}>
      <Title>{children}</Title>
    </Wrapper>
  )
}

H2.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  theme: PropTypes.object,
  color: PropTypes.string,
  border: PropTypes.bool,
}
export default H2
