import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { media } from '../styles/media'

export const SectionTitle = styled.h3`
  margin: 1.25rem 0 0;
  font-size: 0.6875rem;
  line-height: 2.36;
  letter-spacing: 4px;
  font-weight: 500;
  text-transform: uppercase;
  color: #131313;

  ${media.tablet`
    display: block;
    margin: 0.625rem 0 0.375rem;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 2;
    opacity: 0.65;
  `};
`
SectionTitle.displayName = 'SectionTitle'

export const NavList = styled.ul`
  display: ${(props) => (props.isActiveSection ? 'block' : 'none')};
  margin-left: 2.8rem;
  padding-left: 0;

  ${media.tablet`
    display: block;
    margin-left: 0;
  `};
`
NavList.displayName = 'NavList'

export const SectionName = styled.div`
  position: relative;
  line-height: 2;
  font-size: 0.875rem;
  cursor: pointer;
  color: ${(props) =>
    props.isActive ? `${props.theme.navigation.links.hover.color}` : '#A9A9A9'};

  :hover {
    color: ${(props) => props.theme.navigation.links.hover.color};
  }
`
SectionName.displayName = 'SectionName'

export const SubNavList = styled.ul`
  list-style: none;
  padding-left: 0;
`

export const NavGroup = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
`

export const NavLink = styled(Link)`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 2;
  color: #131313;
  cursor: pointer;
  transition: color 0.15s ease-in;

  :hover {
    color: ${(props) => props.theme.navigation.links.hover.color};
    text-decoration: none;
  }

  ::after {
    margin: -5px 0 4px;
  }

  ${media.tablet`    
    font-weight: 300;
    font-size: 0.9375rem;
    line-height: 1.86;
  `} ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.navigation.links.hover.color};

      ::after {
        content: '';
        display: block;
        margin: -4px 0 4px;
        opacity: 1;
        box-shadow: 0 1px 0 1px currentColor;
        transform: scaleX(1);
        transform-origin: left;
        transition: all 0.4s ease;
      }
    `};
`
NavLink.displayName = 'NavLink'

export const SubNavLink = styled(NavLink)`
  font-size: 0.815rem;
  font-weight: 300;

  ::after {
    margin: -5px 0 4px;
  }

  ${media.tablet`    
    font-weight: 300;
  `};
`
SubNavLink.displayName = 'SubNavLink'

export const Toggler = styled.div`
  position: absolute;
  left: -0.8125rem;
  font-size: 0.65rem;
  line-height: 2.5;
  display: ${(props) => (props.visible ? 'inline-block' : 'none')};
  user-select: none;

  :hover {
    color: ${(props) => props.theme.navigation.links.hover.color};
  }

  ${media.tablet`
    display: none;
  `};
`
Toggler.displayName = 'Toggler'

export const SubNav = styled.ul`
  padding: 0.2rem 0 0.2rem 1rem;
  overflow: hidden;
  max-height: ${(props) => (props.open ? '300px' : '0')};
  transition: 0.3s ease;
  transition-property: max-height, opacity;

  ${media.tablet`
    display: block;
    padding: 5px 0 5px 0.9375rem;
  `};
`
SubNav.displayName = 'SubNav'

export const Icon = styled.span`
  display: inline-block;
  margin-right: 1rem;
  height: 1.7rem;
  width: 1.7rem;

  ${media.tablet`
    display: none;
  `} & svg {
    width: 100%;
    height: 100%;
  }
`

export const NavigationGroup = styled.div`
  margin-bottom: 1rem;

  ${media.tablet`
     margin-bottom: 0.625rem;
  `};
`
NavigationGroup.displayName = 'NavigationGroup'

export const NavigationGroupLink = styled.span`
  ::after {
    content: '';
    display: block;
    margin-top: -3px;
    opacity: ${(props) => (props.isActive ? '1' : '0')};
    transform: ${(props) => (props.isActive ? 'scaleX(1)' : 'scaleX(0)')};
    box-shadow: 0 1px 0 1px currentColor;
    transform-origin: left;
    transition: all 0.25s ease;
  }

  :hover::after {
    opacity: 1;
    transform: scaleX(1);
  }
`
NavigationGroupLink.displayName = 'NavigationGroupLink'

export const NavigationGroupTitle = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.625;
  font-weight: 600;
  color: ${(props) => props.color};

  ${media.tablet`
    font-size: 1.0625rem;
  `} :hover {
    cursor: pointer;
    text-decoration: none;
  }
`
NavigationGroupTitle.displayName = 'NavigationGroupTitle'
