import React from 'react'
import PropTypes from 'prop-types'
import { map, has } from 'lodash'

import SchemaTable from '../SchemaTable'

// body parameter is weird and not like the other parameters...
const BodyParameters = ({ definition, theme }) => {
  const { name, schema } = definition
  const { required } = schema

  let properties = []
  if (has(schema, 'items.properties')) {
    // eslint-disable-next-line prefer-destructuring
    properties = schema.items.properties
  }

  if (has(schema, 'properties')) {
    // eslint-disable-next-line prefer-destructuring
    properties = schema.properties
  }

  const parameters = map(properties, (schema, key) => {
    schema.name = key
    return (
      <SchemaTable
        name={name}
        schema={schema}
        isRequired={required && required.includes(name)}
        theme={theme}
      />
    )
  })

  return <div>{parameters}</div>
}

BodyParameters.propTypes = {
  definition: PropTypes.object,
}

export default BodyParameters
