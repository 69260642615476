import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from '../components/Header'
import Layout from '../components/Layout'
import Intro from '../components/Homepage/Intro'
import APISections from '../components/Homepage/APISections'
import Welcome from '../components/Homepage/Welcome'
import References from '../components/Homepage/References'
import Footer from '../components/Footer'

class Homepage extends Component {
  state = {
    headerPinned: false,
  }

  render() {
    return (
      <div>
        <Helmet title="Typeform's Developer Platform" />
        <Layout>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Header />
            <Intro />
            <APISections />
            <Welcome />
            <References />
            <Footer />
          </div>
        </Layout>
      </div>
    )
  }
}

Homepage.propTypes = {
  head: PropTypes.object,
  currentUrl: PropTypes.string,
}

export default Homepage
