import { keyframes } from 'styled-components'

export const blinkingBackground = (props) => {
  const { codeBlockButton } = props.theme
  return keyframes`
  0%, 50%, 100% {
    background-color: ${codeBlockButton.background};
    color: ${codeBlockButton.color};
    border-color: ${codeBlockButton.border};
  }
  
  25%, 75% {
    background-color: ${codeBlockButton.active.background};
    color: ${codeBlockButton.active.color};
    border-color:  ${codeBlockButton.active.border};
  }`
}

export const arrowToggle = (dir) => {
  return keyframes`
    0% {
      transform: translateY(0) rotateX(0);
    }
    50% {
      transform: translateY(${-100 * dir}%) rotateX(${90 * dir}deg);
    }
    100% {
      transform: translateY(0) rotateX(${180 * dir}deg);
    }
  `
}
