import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import DefaultBlocksIcon from '../icons/top-blocks-blue'
import { media } from '../styles/media'

const BackgroundBlocks = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(-335deg) translate(31%, -86%);
  z-index: 0;

  ${media.tablet`
    transform: translate(56%, -55%) rotate(-335deg);
  `} ${media.phone`
    transform: translate(49%, -51%) rotate(-335deg) scale(0.45);
    opacity: 0.3;
  `};
`

export const HeaderWrap = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 10rem;
  padding: 5rem 1.5rem;
  background-color: #f9f9f9;
  overflow: hidden;

  ${media.tablet`
    padding: 2.5rem 1.5rem
  `};
`

export const HeaderContainer = styled.div`
  position: relative;
  align-self: center;
  max-width: 100%;
  width: 730px;
  margin: 0 auto;
  padding: 0;
`

export const Title = styled.h1`
  margin-top: 0;
  max-width: 33.25rem;
  font-size: 2.8125rem;
  line-height: 1.3;
  letter-spacing: -1.5px;

  ${media.tablet`
    font-size: 2.375rem;
    line-height: 1.2;
  `};
`

export const Description = styled.p`
  max-width: 33.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.67px;
  color: #aaa;

  ${media.tablet`
    font-size: 1rem;
  `};
`

const Hero = ({ blocks, children, className }) => {
  return (
    <HeaderWrap className={className}>
      <BackgroundBlocks>{blocks || <DefaultBlocksIcon />}</BackgroundBlocks>
      <HeaderContainer>{children}</HeaderContainer>
    </HeaderWrap>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  links: PropTypes.array,
}

Hero.defaultProps = {
  links: [],
}

export default Hero
