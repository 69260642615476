import React from 'react'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

const styles = css`
  color: ${(props) => props.color};
  font-weight: 600;
  border-bottom: 2px solid currentColor;

  :hover {
    text-decoration: none;
  }
`

const Anchor = styled.a`
  ${styles};
`
const Link = styled(GatsbyLink)`
  ${styles}
`

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  if (props.href.includes('//')) {
    return <Anchor {...props}>{props.children}</Anchor>
  } else {
    return (
      <Link to={props.href} {...props}>
        {props.children}
      </Link>
    )
  }
}
