import React, { PureComponent } from 'react'

import { trackRunInPostmanButton } from '../../utils/tracking'

import { Image } from '.'

class PostmanButton extends PureComponent {
  render() {
    return (
      <a
        href={this.props.href}
        target="_blank"
        onClick={trackRunInPostmanButton}
        rel="noreferrer"
      >
        <Image alt="Run in Postman" src="https://run.pstmn.io/button.svg" />
      </a>
    )
  }
}

export default PostmanButton
