import React from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash'
import styled from 'styled-components'

import { monospace } from '../../styles/variables'

const padding = '2rem'

const Container = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
`

const Gutter = styled.div`
  font-size: 1rem;
  padding: ${padding} 1rem;
  background-color: ${(props) => props.background};
  opacity: 0.85;

  & code {
    display: block;
    text-align: center;
    color: ${(props) => props.theme.snippet.lineColor};
  }
`

// TODO: refactor this so it is not defined twice
Gutter.defaultProps = {
  theme: {
    snippet: {
      lineColor: '#fff',
    },
  },
}

const Snippet = styled.pre`
  display: flex;
  flex: 1;
  margin: 0;
  width: 100%;
  padding: ${padding} 1rem;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(8px);
`

const Code = styled.code`
  font-size: 13px;
  font-family: ${monospace};
`

const CodeSample = ({ code, color }) => {
  const lines = code.split('\n').length
  return (
    <Container>
      <Gutter background={color}>
        {range(0, lines).map((num) => (
          <code key={num}>{num}</code>
        ))}
      </Gutter>
      <Snippet>
        <Code>{code}</Code>
      </Snippet>
    </Container>
  )
}

CodeSample.propTypes = {
  code: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default CodeSample
