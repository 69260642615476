import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Caret from './caret'

const LETTERS_PER_SECOND = 100

class Typer extends Component {
  static propTypes = {
    className: PropTypes.string,
    values: PropTypes.array.isRequired,
    prefix: PropTypes.string,
    delay: PropTypes.number,
    duration: PropTypes.number,
  }

  static defaultProps = {
    values: [],
    prefix: '',
    delay: 2000,
    duration: 1000,
  }

  state = {
    current: 0,
    windowHasFocus: true,
  }

  componentDidMount() {
    this.timer = setInterval(this.nextWord, this.props.delay)
    window.addEventListener('blur', this.windowHasNoFocus)
    window.addEventListener('focus', this.windowHasFocus)
  }

  windowHasFocus = () => this.setState({ windowHasFocus: true })

  windowHasNoFocus = () => this.setState({ windowHasFocus: false })

  componentWillUnmount() {
    clearInterval(this.typer)
    clearInterval(this.timer)
    window.removeEventListener('blur', this.windowHasNoFocus)
    window.removeEventListener('focus', this.windowHasFocus)
  }

  nextWord = () => {
    const { current, windowHasFocus } = this.state

    if (!windowHasFocus) {
      return false
    }

    let next = current + 1
    if (next === this.props.values.length) {
      next = 0
    }

    this.typer = setInterval(this.transitionWord, LETTERS_PER_SECOND)

    this.setState({
      current: next,
      letters: 0,
    })
  }

  transitionWord = () => {
    if (this.state.letters < this.getCurrentWord().length) {
      this.setState({ letters: this.state.letters + 1 })
      return clearInterval(this.timer)
    } else {
      this.timer = setInterval(this.nextWord, this.props.delay)
      return clearInterval(this.typer)
    }
  }

  getCurrentWord = () => {
    return this.props.values[this.state.current]
  }

  getWord = () => {
    const word = this.getCurrentWord()
    return word.substr(0, this.state.letters)
  }

  render() {
    return (
      <span className={this.props.className}>
        {this.props.prefix} {this.getWord()}
        <Caret />
      </span>
    )
  }
}

export default Typer
