import React from 'react'
import PropTypes from 'prop-types'

import {
  Heading,
  ParameterTable,
  Sample,
  Subheading,
} from '../Operation/shared-components'
import SchemaProperty from '../SchemaProperty'
import BodyParameters from '../BodyParameters'

// The non `body` parameters are rendered as SchemaProperty
// because they will always be a scalar value
const PARAMETER_TYPES = [
  {
    type: 'query',
    displayHeader: 'Query Parameters',
    Component: SchemaProperty,
  },
  {
    type: 'header',
    displayHeader: 'Header Parameters',
    Component: SchemaProperty,
  },
  {
    type: 'path',
    displayHeader: 'Path Parameters',
    Component: SchemaProperty,
  },
  {
    type: 'formData',
    displayHeader: 'Form Parameters',
    Component: SchemaProperty,
  },
]

const Parameters = ({ parameters = [], body, theme }) => {
  const renderBodyParams = () => {
    if (!body) {
      return null
    }

    return (
      <div key="body">
        <Subheading>Request Body</Subheading>
        <ParameterTable>
          <BodyParameters
            definition={{ name: 'body', schema: body }}
            theme={theme}
          />
        </ParameterTable>
      </div>
    )
  }

  return (
    <div>
      {parameters
        ? PARAMETER_TYPES.map(({ type, displayHeader, Component }) => {
            const filtered = parameters.filter(
              (parameter) => parameter.in === type
            )

            if (!filtered.length) {return null}

            return (
              <div key={type}>
                <Subheading>{displayHeader}</Subheading>
                <ParameterTable>
                  {filtered.map((parameter) => (
                    // eslint-disable-next-line react/jsx-key
                    <Component definition={parameter} theme={theme} />
                  ))}
                </ParameterTable>
              </div>
            )
          })
        : null}
      {renderBodyParams()}
    </div>
  )
}

const Request = ({ operation, theme }) => {
  const { parameters = [], example = {}, body = '' } = operation.operation
  if (!parameters && !example) {return null}
  const bodyObject = JSON.parse(body)
  const bodyExample = bodyObject ? JSON.stringify(bodyObject.example) : ''
  return (
    <div>
      <Heading>Request</Heading>
      <Parameters parameters={parameters} body={bodyObject} theme={theme} />
      <Sample sample={example.request || bodyExample} theme={theme} />
    </div>
  )
}

Request.propTypes = {
  parameters: PropTypes.array,
  theme: PropTypes.object,
}

export default Request
