import styled from 'styled-components'

import { monospace } from '../styles/variables'

const InlineTag = styled.span`
  margin-right: 0.625rem;
  padding: 0.2rem 0.375rem;
  font-family: ${monospace};
  font-size: 0.75rem;
  font-weight: bold;
  background: ${(props) => props.theme.inlineTag.background};
  color: rgba(${(props) => props.theme.inlineTag.color});
  border-radius: 2px;
  transition: 0.15s ease;
  transition-property: background-color, color;
`

export default InlineTag
