import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media } from '../styles/media'
import theme from '../styles/theme.json'

export const Content = ({ centeredLayout, children, closePortalNav }) => (
  <ContentOuter onClick={closePortalNav}>
    <ContentInner centeredLayout={centeredLayout}>{children}</ContentInner>
  </ContentOuter>
)

const ContentOuter = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100% - 48px);
  flex: 1 0 50rem;
  padding: 0 2rem 2rem;

  @media only screen and (max-width: 1200px) and (min-width: 1024px) {
    flex: 1 0 47rem;
  }

  ${media.tabletLandscape`
    padding: 0 2.4rem;
  `} ${media.tablet`
    flex: inherit;
    padding: 0 1.5rem;
  `};
`

ContentOuter.defaultProps = {
  theme: theme.light,
}

const ContentInner = styled.div`
  width: 100%;
  max-width: 45.625rem;
  margin: ${(props) => (props.centeredLayout ? '0 auto 2rem' : '0 2rem 2rem')};

  @media only screen and (max-width: 1200px) and (min-width: 1024px) {
    max-width: 39rem;
  }

  ${media.tablet`
    margin: 2rem 0;
  `};
`

Content.propTypes = {
  closePortalNav: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object, // it avoid errors in the console related to the type
  ]),
  centeredLayout: PropTypes.bool,
}

export default Content
