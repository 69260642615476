import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'linkedin'}</title>
      <path
        d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281zm4.954-9.036v3.311h-1.919v-3.089c0-.776-.277-1.305-.972-1.305-.53 0-.845.357-.985.702-.051.123-.064.295-.064.468v3.224h-1.92s.026-5.231 0-5.773h1.92v.818l-.013.019h.013v-.019c.255-.393.71-.954 1.73-.954 1.263 0 2.21.825 2.21 2.598zM8.087 6c-.657 0-1.086.431-1.086.997 0 .554.417.998 1.061.998h.012c.669 0 1.086-.443 1.086-.998C9.147 6.431 8.744 6 8.087 6zm-.972 8.556h1.919V8.783H7.115v5.773z"
        fill="#0077B5"
      />
    </svg>
  )
}

export default SvgComponent
