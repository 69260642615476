import React from 'react'
import styled from 'styled-components'

import CreateIcon from './create'
import ResponsesIcon from './responses'
import WebHookIcon from './webhooks'
import EmbedIcon from './embed'
import CodepenIcon from './social/codepen'
import DribbbleIcon from './social/dribbble'
import FacebookIcon from './social/facebook'
import GithubIcon from './social/github'
import GoogleIcon from './social/google'
import InstagramIcon from './social/instagram'
import LinkedinIcon from './social/linkedin'
import MediumIcon from './social/medium'
import RedditIcon from './social/reddit'
import SnapchatIcon from './social/snapchat'
import TwitterIcon from './social/twitter'
import WebsiteIcon from './social/web'

export const Icon = styled.span`
  display: inline-block;
  width: ${(props) => (props.size ? `${props.size}px` : 'auto')};
  height: ${(props) => (props.size ? `${props.size}px` : 'auto')};

  svg {
    width: 100%;
    height: 100%;
  }
`

export const product = {
  create: <CreateIcon />,
  responses: <ResponsesIcon />,
  webhooks: <WebHookIcon />,
  embed: <EmbedIcon />,
}

export const social = {
  codepen: <CodepenIcon />,
  dribbble: <DribbbleIcon />,
  facebook: <FacebookIcon />,
  github: <GithubIcon />,
  google: <GoogleIcon />,
  instagram: <InstagramIcon />,
  linkedin: <LinkedinIcon />,
  medium: <MediumIcon />,
  reddit: <RedditIcon />,
  snapchat: <SnapchatIcon />,
  twitter: <TwitterIcon />,
  website: <WebsiteIcon />,
}

export default product
