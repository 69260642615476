import { css } from 'styled-components'

import { cdnURL } from './variables'

export const sprite = (x, y) =>
  css`
    background-color: transparent;
    background: url(${cdnURL}/dist/img/common_sprites.png) ${x}px ${y}px;
    background-repeat: no-repeat;
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
      only screen and (min-resolution: 120dpi) {
      background: url(${cdnURL}/dist/img/common_sprites@2x.png) ${x}px ${y}px;
      background-size: 400px 400px;
    }
  `

export const buttonReset = () => css`
  -webkit-appearance: none;
  border: none;
  background: none;
  padding: 0;
`
