import React, { useEffect, useRef } from 'react'
import { css } from 'styled-components'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {
  ONETRUST_EVENT,
  scriptSrc,
  defaultDataDomainScript,
} from '@typeform/js-tracking/lib/consents/consentUtil'
import ConsentListener from '@typeform/js-tracking/lib/consents/consentListener'
import { GlobalStyle } from '@typeform/ginger/dist/util/global'
import { MainProvider } from '@typeform/ginger/dist/components/main-context'

import {
  trackViewPageSectionEvent,
  initialiseTrackingService,
} from '../utils/tracking'
import socialImg from '../components/images/social-share.png'

import './normalize.css'
import './prismjs.css'

// Replace some styles from ginger to make it look like the previous
// versions.
const customGlobalStyle = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  body {
    line-height: 1.5rem;
  }
`

const DefaultLayout = ({ children }) => {
  const isTrackingInitialized = useRef(false)

  const handleConsentUpdate = () => {
    if (!isTrackingInitialized.current) {
      isTrackingInitialized.current = true
      initialiseTrackingService()
      trackViewPageSectionEvent()
    }
  }

  useEffect(() => {
    handleConsentUpdate()
  }, [])

  return (
    <div>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
        />

        <meta name="robots" content="index,follow" />

        <meta
          name="google-site-verification"
          content="43JLMuD6nlmLxzm5Rb7y1i5jkKZw2RMlk6A7biqmGy4"
        />

        <meta name="msapplication-TileColor" content="#000000" />
        <meta
          name="msapplication-config"
          content="https://public-assets.typeform.com/public/favicon/browserconfig.xml"
        />
        <meta name="theme-color" content="#131313" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://public-assets.typeform.com/public/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://public-assets.typeform.com/public/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://public-assets.typeform.com/public/favicon/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="https://public-assets.typeform.com/public/favicon/site.webmanifest"
        />
        <link
          rel="shortcut icon"
          href="https://public-assets.typeform.com/public/favicon/favicon.ico"
        />
        <link
          rel="mask-icon"
          href="https://public-assets.typeform.com/public/favicon/safari-pinned-tab.svg"
          color="#000000"
        />
        <meta property="og:image" content={socialImg} />
        <meta name="twitter:image" content={socialImg} />

        <link
          rel="stylesheet"
          href="https://font.typeform.com/dist/font.css"
          type="text/css"
          media="all"
        />
        <style>{'@-ms-viewport { width: device-width; }'}</style>
        <script
          charSet="UTF-8"
          data-domain-script={defaultDataDomainScript}
          src={scriptSrc}
          type="text/javascript"
        />
        <script>
          {`function OptanonWrapper() {
            window.dataLayer.push({ event: '${ONETRUST_EVENT}' })
            window.dispatchEvent(new Event('${ONETRUST_EVENT}'))
          }`}
        </script>
      </Helmet>
      <ConsentListener onConsentUpdate={handleConsentUpdate} />
      <GlobalStyle customGlobalStyle={customGlobalStyle} />
      <MainProvider locale="en">{children}</MainProvider>
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.any,
}

export default DefaultLayout
