import { css } from 'styled-components'

export const media = {
  phone: (...args) => css`
    @media screen and (max-width: 420px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media screen and (max-width: 768px) {
      ${css(...args)};
    }
  `,
  tabletLandscape: (...args) => css`
    @media screen and (max-width: 1024px) {
      ${css(...args)};
    }
  `,
}

export const largerThan = {
  phone: (...args) => css`
    @media screen and (min-width: 421px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media screen and (min-width: 769px) {
      ${css(...args)};
    }
  `,
  tabletLandscape: (...args) => css`
    @media screen and (min-width: 1025px) {
      ${css(...args)};
    }
  `,
}
