import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'google'}</title>
      <path
        d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281zm1.343-8.282c-.301 1.974-1.772 3.106-3.678 3.106a3.878 3.878 0 110-7.755c1.036 0 1.944.37 2.604 1.011l-1.113 1.113c-.377-.38-.893-.582-1.491-.582-1.261 0-2.283 1.075-2.283 2.335 0 1.261 1.022 2.305 2.283 2.305 1.057 0 1.857-.511 2.087-1.533H8.665v-1.557h3.669c.044.251.066.513.066.786 0 .27-.02.527-.057.772zM17 11.516h-1.359v1.358h-.965v-1.358h-1.359v-.966h1.359V9.191h.965v1.359H17v.966z"
        fill="#DD4B39"
      />
    </svg>
  )
}

export default SvgComponent
