import React from 'react'
import styled from 'styled-components'
import { Link as ScrollLink } from 'react-scroll'

import { media } from '../../styles/media'
import { gridWidth, lightGrey, monospace } from '../../styles/variables'

import apis from './apiData'

const Container = styled.div`
  display: flex;
  max-width: ${gridWidth};
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 auto;

  ${media.tablet`
    padding: 0 1.6rem;
  `};
`

const OuterContainer = styled.div`
  display: block;
  margin: 1rem auto 4rem;
`

const BlockLink = styled(ScrollLink)`
  flex: 1;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  ${media.tablet`
    display: flex;
    width: 50%;
    flex-basis: 50%;
    align-items: center;
    margin: 0.75rem 0;
  `};
`

// eslint-disable-next-line no-unused-vars
const Icon = styled.svg`
  display: flex;

  ${media.phone`
    & svg {
      width: 3.125rem;
      height: 3.125rem;
    }
  `};
`

const ContentWrap = styled.div`
  ${media.tablet`
    margin-left: 1.25rem;
  `} ${media.phone`
    margin-left: 0;
  `};
`

const Title = styled.h4`
  font-size: 1.125rem;
  margin: 1rem 0 0;
  font-family: ${monospace};
  color: ${(props) => props.color};

  ${media.phone`
    margin: 0 0 0 .65rem;
    font-size: 0.9375rem;
    max-width: 3.75rem;
    line-height: 1.2;
  `};
`

const Description = styled.p`
  font-size: 0.875rem;
  margin-top: 0.75rem;
  padding: 0 2rem 0 0;
  line-height: 1.5;
  font-weight: 600;
  color: ${lightGrey};

  ${media.phone`
    display: none;
  `};
`

const APISections = () => {
  return (
    <OuterContainer>
      <Container>
        {apis.map(([category, title, description, color, icon]) => (
          <BlockLink
            to={category}
            offset={-50}
            duration={500}
            smooth
            key={category}
          >
            {icon}
            <ContentWrap>
              <Title color={color}>{title}</Title>
              <Description>{description}</Description>
            </ContentWrap>
          </BlockLink>
        ))}
      </Container>
    </OuterContainer>
  )
}

export default APISections
