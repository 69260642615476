import React from 'react'
import { Link, graphql } from 'gatsby'
import { sortBy } from 'lodash'
import styled from 'styled-components'

import Content from '../components/Content'

const List = styled.ul`
  list-style: none;
  padding: 0;
`

const Sitemap = ({ data }) => {
  const nodes = data.allSitePage.edges.map((edge) => edge.node)
  return (
    <div>
      <Content>
        <h1>Sitemap</h1>
        <List>
          {sortBy(nodes, (node) => node.path.replace(/\//g, ''))
            .map((node) => ({
              path: node.path,
              contentDigest: node.internal.contentDigest.substr(0, 8),
            }))
            .filter(({ path }) => !/(404|extra_files)/.test(path))
            .map(({ path, contentDigest }) => {
              return (
                <li key={contentDigest}>
                  <Link to={path}>{path}</Link>
                </li>
              )
            })}
        </List>
      </Content>
    </div>
  )
}

export default Sitemap

export const pageQuery = graphql`
  query SitemapQuery {
    allSitePage {
      edges {
        node {
          path
          internal {
            contentDigest
          }
        }
      }
    }
  }
`
