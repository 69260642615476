import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import H1 from '../Markdown/H1'
import H2 from '../Markdown/H2'
import Markdown from '../Markdown/renderer'
import Endpoint from '../Endpoint'
import Request from '../Request'
import Response from '../Response'

export const DescriptionWrap = styled.div`
  margin-top: 1.15rem;
`

const Operation = ({ operation, theme }) => {
  const {
    title,
    category,
    method,
    path,
    operation: operationSchema,
  } = operation
  const { description } = operationSchema
  return (
    <div>
      <H1 color={theme.color} anchor={title} border={false}>
        {category}
      </H1>
      <H2 color={theme.color} anchor={title} border={false}>
        {title}
      </H2>
      <Endpoint method={method} path={path} />
      <DescriptionWrap>
        <Markdown text={description} theme={theme} />
      </DescriptionWrap>
      <Request operation={operation} theme={theme} />
      <Response id={operationSchema.id} operation={operation} theme={theme} />
    </div>
  )
}

Operation.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.object,
  operation: PropTypes.object,
}

export default Operation
