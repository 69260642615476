import { kebabCase } from 'lodash'

export const convertChildrenIntoId = (children) => {
  const contents = Array.isArray(children) ? children[0] : children
  if (typeof contents === 'string') {
    return kebabCase(contents)
  } else {
    return null
  }
}
