import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import textContent from 'react-addons-text-content'

import { media } from '../styles/media'

import { convertChildrenIntoId } from './utils'

export const Wrapper = styled.h1`
  position: relative;
  margin: 3rem 0 2rem;
  font-size: 2.375rem;
  line-height: 1.4;
  color: ${(props) => props.color};

  ${media.phone`
    margin: 0;
  `} + span > h2 {
    padding-top: 0;
  }

  + p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
  }
`

export const H1 = ({ color, children, ...props }) => {
  const id = convertChildrenIntoId(children)
  const text = textContent(children)
  return (
    <Wrapper id={id} color={color} {...props}>
      {text}
    </Wrapper>
  )
}

H1.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  color: PropTypes.string,
}

export default H1
