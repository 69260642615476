import React from 'react'

import CreateIcon from '../../icons/create'
import ResponsesIcon from '../../icons/responses'
import WebhooksIcon from '../../icons/webhooks'
import EmbedIcon from '../../icons/embed'

const Showcases = [
  {
    color: '#89bc62',
    name: 'create',
    title: 'Create API',
    desc: 'Create, update, delete, and customize typeforms, themes, and images on the fly, without using the Typeform builder.',
    sample: 'create-sample.js',
    icon: <CreateIcon width={50} height={50} />,
  },
  {
    color: '#dfba28',
    name: 'responses',
    title: 'Responses API',
    desc: 'Access the submissions for your typeforms in JSON format, without setting up webhooks or third-party integrations.',
    sample: 'responses-sample.js',
    icon: <ResponsesIcon width={50} height={50} />,
  },
  {
    color: '#c187ca',
    name: 'webhooks',
    title: 'Webhooks API',
    desc: "Send every submission for your typeform directly to any URL or compatible web application, as soon as it's submitted.",
    sample: 'webhooks-sample.js',
    icon: <WebhooksIcon width={50} height={50} />,
  },
  {
    color: '#c5721b',
    name: 'embed',
    title: 'Embed SDK',
    desc: "Integrate your typeform into your website or web app---it will look like a part of your site, and people won't have to leave your site to respond.",
    sample: 'embed-sample.js',
    icon: <EmbedIcon width={50} height={50} />,
  },
]

export default Showcases
