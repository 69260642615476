import DomConfetti from 'react-dom-confetti'
import styled from 'styled-components'

import { gridWidth } from '../../styles/variables'
import { darkGrey } from '../../styles/variables'
import { media, largerThan } from '../../styles/media'

export const Container = styled.div`
  position: relative;
  padding: 8.125rem 0 6.25rem;
  background: ${darkGrey};
  color: #fff;
  transition: 0.1s ease;
  transition-property: background, color;
  ${media.phone`
    padding-top: 55px;
  `};
`

export const Grid = styled.div`
  position: relative;
  max-width: ${gridWidth};
  margin: 0 auto;
  z-index: 2;
  display: flex;
  flex-direction: column;

  ${media.tablet`
    padding: 0 1.6rem;
  `} > div:nth-child(even) > div > div:first-child {
    order: 2;
  }

  ${largerThan.tablet`
  > div:nth-child(even) > div > div:first-child {
    padding-left: 5.5rem;
  }`};
`

export const Confetti = styled(DomConfetti)`
  width: 0;
  margin: 0 auto;
  transform: translateZ(0px); /* fix safari bug */
`

export const Title = styled.h2`
  font-size: 2rem;
  margin: -1rem 0 3rem;
  text-align: center;

  ${media.phone`
    margin: 0;
    max-width: 15rem;
    font-size: 1.75rem;
    line-height: 1.375;
    text-align: left;
    letter-spacing: -1.5px;
  `};
`

export const Subtitle = styled.p`
  font-size: 1.125rem;
  max-width: 38rem;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.625;
  text-align: center;
  font-weight: 500;
  opacity: 0.75;

  ${media.phone`
    font-size: 1rem;
    margin: 1rem 0;
    padding-bottom: 1.2rem;
    line-height: 1.625;
    text-align: left;
  `};
`
