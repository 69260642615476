import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { largerThan } from '../styles/media'
import { buttonReset } from '../styles/mixins'
import BurgerIcon from '../icons/open-sidebar.svg'

import GingerHeader from './ginger-header'
import { HEADER_HOOKS } from './constants'

const navItems = (currentPage) => [
  {
    label: 'Documentation',
    link: '/get-started/',
    /* todo: this is not a correct condition */
    isActive: currentPage === '/get-started/',
  },
  {
    label: 'Community',
    link: '/community/',
    isActive: currentPage === '/community/',
  },
  {
    label: 'Troubleshooting',
    link: '/troubleshooting/',
    isActive: currentPage === '/troubleshooting/',
  },
  {
    label: 'Changelog',
    link: '/changelog/',
    isActive: currentPage === '/changelog/',
  },
  {
    label: 'Get Involved',
    link: 'https://developerplatform.typeform.com/to/Xc7NMh?source=portal_home',
    isActive: false,
    useRouter: false,
  },
  {
    label: 'Support',
    link: 'https://www.typeform.com/help/contact/',
    isActive: false,
    useRouter: false,
  },
]

const BurgerIconButton = styled.button`
  ${buttonReset};
  height: 100%;
  margin: 0 24px 0 0;
  outline: none;

  ${largerThan.tabletLandscape`
    display: none;
  `};

  svg {
    display: block;
  }
`

const SidebarButton = (props) => {
  return (
    <BurgerIconButton {...props}>
      <BurgerIcon />
    </BurgerIconButton>
  )
}
const DevPortalHeader = ({ isDocPage, currentPath, onSidebarClick }) => {
  const onSidebarIconClick = (ev) => {
    ev.stopPropagation()
    onSidebarClick()
  }

  const hooks = {
    [HEADER_HOOKS.BEFORE_LOGO]: isDocPage
      ? () => <SidebarButton onClick={onSidebarIconClick} />
      : null,
  }

  const Anchor = ({ href, label, isActive, useRouter = true, ...rest }) =>
    useRouter ? (
      <Link to={href} isActive={() => isActive} {...rest}>
        {label}
      </Link>
    ) : (
      <a href={href} {...rest}>
        {label}
      </a>
    )

  return (
    <GingerHeader
      id="header"
      hooks={hooks}
      links={navItems(currentPath)}
      renderLink={Anchor}
      displaySignUpOnTablet
      title="Developers"
      compact
    />
  )
}

DevPortalHeader.defaultProps = {
  currentPath: '/',
}

Link.contextTypes = {
  router: PropTypes.any,
}

export default DevPortalHeader
