import React from 'react'
import PropTypes from 'prop-types'

import { SectionTitle, SubNavList, NavLink } from '../visual-components'
import { normalizePath } from '../../../utils'

const GuideLinks = ({ currentPath, children }) => {
  const path = normalizePath(currentPath)
  return (
    <div>
      <SectionTitle>Guides</SectionTitle>
      <SubNavList>
        {children.map(({ title, href }) => (
          <li key={title}>
            <NavLink to={href} active={path === normalizePath(href)}>
              {title}
            </NavLink>
          </li>
        ))}
      </SubNavList>
    </div>
  )
}

GuideLinks.propTypes = {
  currentPath: PropTypes.string,
}

export default GuideLinks
