import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media } from '../styles/media'
import theme from '../styles/theme.json'

export const PageContainer = styled.div`
  display: flex;
`

const SidebarOuter = styled.div`
  display: flex;
  flex: 2;
  min-height: 100%;
  background-color: ${(props) => props.theme.navigation.background};
  color: ${(props) => props.theme.navigation.color};
  transition: 0.15s ease;
  transition-property: background-color, color;

  ${media.tabletLandscape`
    position: fixed;
    top: 0;
    width: 18rem;
    transform: translateX(-18rem);
  `};
`

SidebarOuter.defaultProps = {
  theme: theme.light,
}

const SidebarInner = styled.div`
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-width: 18.75rem;
  max-height: 100vh;
  padding: 1.875rem;
  margin-left: auto;
  overflow-y: auto;
  will-change: transform;
  -webkit-overflow-scrolling: touch;

  transform: translateY(${(props) => (props.isHeaderPinned ? 87 : 0)}px);
  transition: transform 0.2s ease;

  ${media.tabletLandscape`
    min-width: 0;
    width: 100%;
  `} ${media.tablet`
    padding: 1.875rem 1.5625rem;
  `};
`

export const ToggleWrap = styled.div`
  position: relative;
  top: 2rem;
  width: 100%;
  text-align: right;
  z-index: 2;
  height: 0;
  -webkit-tap-highlight-color: transparent;
  pointer-events: none;
`

export const FlexWrapper = styled.div`
  height: 100%;
  min-height: calc(100vh - 87px);
  display: flex;
`

export const ContentWrap = styled.div`
  flex: 1 0 47rem;
  width: 100%;
  background-color: ${(props) => props.theme.page.background};
  color: ${(props) => props.theme.page.color};
  transition: 0.15s ease;
  transition-property: background-color, color;

  ${media.tablet`
    flex: inherit;
  `};
`

export const SidebarWrap = ({ children, isHeaderPinned }) => {
  return (
    <SidebarOuter>
      <SidebarInner isHeaderPinned={isHeaderPinned}>{children}</SidebarInner>
    </SidebarOuter>
  )
}

SidebarWrap.propTypes = {
  isHeaderPinned: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // it avoid errors in the console related to the type
    PropTypes.array,
  ]),
}
