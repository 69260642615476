import styled from 'styled-components'

import { monospace } from '../styles/variables'
import { media } from '../styles/media'
import { arrowToggle } from '../styles/animations'

export const PreWrap = styled.div`
  position: relative;
  margin-bottom: 1.7rem;
  border: 1px solid ${(props) => props.theme.codeBlock.border};
  box-shadow: ${(props) => props.theme.codeBlock.shadow};
  max-height: ${(props) =>
    props.expanded ? `${props.naturalHeight + 40}px` : '17.5rem'};
  margin: 1.875rem 0;
  padding-bottom: ${(props) => (props.expandable ? '2.75rem' : '0')};
  overflow: hidden;
  border-radius: 5px;
  transition: all 0.5s ease;
`

export const Pre = styled.pre`
  width: 100%;
  height: 100%;
  margin-top: 0;
  padding: 1.25rem 6rem 1.25rem 1.875rem;
  overflow: hidden;
  margin-bottom: 0;
  min-height: 66px;
  ${media.tablet`
    padding-right: 1.25rem;
  `};
`

export const Toolkit = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  margin: 1.25rem;

  ${media.tablet`
    display: none;
  `};
`

export const ExpandButton = styled.button`
  margin-right: 0.5rem;
  background: ${(props) => props.theme.codeBlockButton.background};
  border: 1px solid ${(props) => props.theme.codeBlockButton.border};
  box-shadow: 0 1px 3px 0 rgba(55, 64, 74, 0.14);
  border-radius: 2px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: ${(props) => props.theme.codeBlockButton.hover.background};
    border-color: ${(props) => props.theme.codeBlockButton.hover.border};
  }

  &:focus {
    outline: none;
  }

  & svg {
    vertical-align: middle;
  }

  & path {
    fill: ${(props) => props.theme.codeBlockButton.color};
  }

  & g > g {
    transform-origin: center;
    animation-fill-mode: forwards;
  }

  & g > g:first-child {
    animation: ${(props) => arrowToggle(props.expanded ? 1 : 3)} 0.5s ease;
    animation-fill-mode: forwards;
    animation-direction: ${(props) => (props.expanded ? 'normal' : 'reverse')};
  }

  & g > g:last-child {
    animation: ${(props) => arrowToggle(props.expanded ? -1 : -3)} 0.5s ease;
    animation-fill-mode: forwards;
    animation-direction: ${(props) => (props.expanded ? 'normal' : 'reverse')};
  }
`

export const ShowAll = styled.button`
  appearance: none;
  border: 0;
  color: ${(props) => props.theme.codeBlock.color};
  background: ${(props) => props.theme.codeBlock.showAllButton};
  font-family: ${monospace};
  font-size: 10px;
  letter-spacing: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  font-weight: 600;
  padding: 1rem 0;
  font-size: 0.625rem;
  border-top: 1px solid ${(props) => props.theme.codeBlock.border};
  border-radius: 0 0 5px 5px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  :focus,
  :active {
    outline: none;
  }

  :before {
    content: '';
    position: absolute;
    top: calc(-5rem - 1px);
    display: block;
    width: 100%;
    height: 5rem;
    background-image: ${(props) => props.theme.codeBlock.clipGradient};
    opacity: ${(props) => (props.expanded ? 0 : 1)};
    transition: opacity 0.25s ease;
  }
`
