import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

import CodeSample from '../CodeSample'
import { monospace } from '../../styles/variables'
import { media } from '../../styles/media'

const Container = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 6rem;

  ${media.tablet`
    max-width: 38rem;
    margin: 4rem auto 0;
  `};
`

const Block = styled.div`
  flex: 1;
  width: 50%;

  ${media.tablet`
    ${(props) => (props.hideOnTablet ? 'display: none;' : '')}
  `};
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
`

const Title = styled.h3`
  margin: 0 0 0 1rem;
  font-size: 1.125rem;
  font-family: ${monospace};
  font-weight: bold;
  color: ${(props) => props.color};
`

const Description = styled.p`
  margin: 3rem 0 2rem;
  max-width: 20rem;
  font-weight: 500;
  letter-spacing: -0.2px;

  ${media.tablet`
    max-width: 100%;
    margin: 1.875rem 0;
  `};
`

const Button = styled(Link)`
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.8rem 1rem;
  line-height: 2.1;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  border-top: 1px solid rgba(#fff, 0.1);
  background-color: ${(props) => props.dataBackground};
  box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0);
  outline-offset: 2px;
  transition: transform 0.2s ease, outline 0.2s ease, outline-offset 0.2s ease;

  &:before {
    content: '→';
    font-family: 'Lucida Grande', sans-serif;
    padding-right: 0.5rem;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px -1px rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }

  &:active,
  &:focus {
    outline: 2px solid #407dbf;
    outline-offset: 3px;
  }

  ${media.tablet`
    font-weight: 600;
    padding: .75rem 1rem;
  `};
`

const Showcase = ({ color, icon, title, description, link, code }) => {
  return (
    <Container>
      <Block>
        <TitleWrap>
          <Icon>{icon}</Icon>
          <Title color={color}>{title.replace(/ /g, '_')}</Title>
        </TitleWrap>
        <Description>{description}</Description>
        <Button to={link} dataBackground={color}>
          Learn more
        </Button>
      </Block>
      <Block hideOnTablet>
        <CodeSample code={code} color={color} />
      </Block>
    </Container>
  )
}

Showcase.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  code: PropTypes.string,
}

export default Showcase
