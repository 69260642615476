import { keyframes } from 'styled-components'

export const bounce = `cubic-bezier(0.68, -0.55, 0.27, 1.55)`

export const menuAppear = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

export const mobileMenuAppear = keyframes`
  from {
    opacity: .5;
    clip-path: circle(50px at 94.75% 8.5%);
  }
  to {
    opacity: 1;
    clip-path: circle(150vmax at 94.75% 8.5%);
  }
`

export const menuListItemAppear = keyframes`
  from {
    opacity: 0;
    transform: translateY(24px);
  }
  to {
    opacity: 1;
    transform: translate(0);
  }
`

export const closeButtonAppear = keyframes`
  from {
    transform: scale(0.1);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`
