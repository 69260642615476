import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'web'}</title>
      <g fill="#73BEC8">
        <path d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281z" />
        <path d="M7.283 11.299l-1.078 1.078c-.94.94-.94 2.478 0 3.418s2.478.94 3.418 0l1.078-1.078c.94-.94.94-2.478 0-3.418s-2.478-.94-3.418 0zm2.734 2.734l-1.078 1.078a1.453 1.453 0 01-2.051 0 1.453 1.453 0 010-2.051l1.078-1.078a1.453 1.453 0 012.051 0 1.453 1.453 0 010 2.051zm2.36-7.828l-1.078 1.078c-.94.94-.94 2.478 0 3.418s2.478.94 3.418 0l1.078-1.078c.94-.94.94-2.478 0-3.418s-2.478-.94-3.418 0zm2.734 2.734l-1.078 1.078a1.453 1.453 0 01-2.051 0 1.453 1.453 0 010-2.051l1.078-1.078a1.453 1.453 0 012.051 0 1.453 1.453 0 010 2.051zm-1.931.565a.483.483 0 10-.684-.684L8.82 12.496a.483.483 0 10.684.684l3.676-3.676z" />
      </g>
    </svg>
  )
}

export default SvgComponent
