import styled from 'styled-components'

import { largerThan } from '../styles/media'
import HeroBase from '../Hero'
import { media } from '../styles/media'

export const Hero = styled(HeroBase)`
  padding-bottom: 8.375rem;
`

export const Column = styled.div`
  flex: 1 0 auto;
  max-width: 100%;

  ${largerThan.tablet`
    flex: 1;
    max-width: calc(50% - 30px);
    
    &:nth-child(odd) {
      margin-right: 30px;
    }
  `};
`

export const ProjectsWrap = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 730px;
  margin: -6rem auto 0;
  z-index: 2;

  ${media.phone`
  padding: 0 25px;
  `};
`
