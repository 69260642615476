import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={100} height={100} viewBox="0 0 100 100" {...props}>
      <g strokeWidth={6} stroke="#CB732B" fill="none" fillRule="evenodd">
        <rect x={3} y={3} width={94} height={94} rx={7} />
        <path d="M37.966 38.69l-10.44 10.787 10.469 10.707M55.981 36L45.113 62m18.921-23.31l10.44 10.787-10.469 10.707" />
      </g>
    </svg>
  )
}

export default SvgComponent
