import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import InlineTag from '../InlineTag'
import {
  defaultFontFamily,
  monospace,
  colorPrimaryDark,
  rose,
} from '../styles/variables'

export const ParameterName = styled.span`
  min-width: ${(props) => 13 - props.level * 1.25}rem;
  margin-left: ${(props) => Math.max(0, props.level - 1) * 1.25}rem;
  margin-right: 1.25rem;
  text-align: right;
  color: ${colorPrimaryDark};
  font-weight: bold;
  cursor: ${(props) => (props.expandable ? 'pointer' : 'auto')};
`

export const ParameterValue = styled.div`
  min-width: 0px; /* lol css */
  padding-bottom: 1rem;
  flex: 1;
  color: #aaa;
  border-bottom: ${(props) => (props.noBorder ? 'none' : `1px solid #ebebeb`)};
`

export const Row = styled.div`
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;

  :last-child > ${ParameterValue} {
    border-bottom: none;
  }
`

export const ArrayFont = styled.span`
  font-family: ${defaultFontFamily};
  font-size: 0.875rem;
  color: ${colorPrimaryDark};
`

export const RequiredFont = styled.span`
  font-family: ${monospace};
  color: ${rose};
`

// Need this wrapper so break will not split words
const EnumTagWrapper = styled.span`
  display: inline-block;
  line-height: 1.9;
`

const EnumTag = styled(InlineTag)`
  background-color: #eee;

  :before {
    content: '“';
  }
  :after {
    content: '”';
  }
`

export const Enum = ({ value }) => {
  return (
    <EnumTagWrapper>
      <EnumTag>{value}</EnumTag>
    </EnumTagWrapper>
  )
}

Enum.propTypes = {
  value: PropTypes.string,
}

export const ParameterDetails = styled.div`
  font-family: ${defaultFontFamily};
  font-size: 0.875rem;
  color: ${colorPrimaryDark};
  word-break: break-word;

  p {
    margin: 0;
    font-size: 0.875rem;
  }
`

export const ExpandWrapper = styled.div`
  flex-basis: 100%;
  margin-top: -1px;
  /* TODO: calculate height to get the animation */
  max-height: ${(props) => (props.expanded ? '3000px' : '0')};
  overflow: hidden;
  transition: all 0.5s ease-in;
`

export const SpanWithRightMargin = styled.span`
  margin-right: 0.5rem;
`

export const ToggleIcon = styled.span`
  display: inline-block;
  transform: rotate(${(props) => (props.expanded ? 0 : 180)}deg);
  transition: transform 0.25s ease;
  user-select: none;
`
