import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={8} height={16} viewBox="0 0 8 16" {...props}>
      <g strokeWidth={0.2} stroke="#37404a" fill="#37404a">
        <path d="M3.506.751L.689 3.568a.525.525 0 10.743.743l2.445-2.446 2.446 2.446a.525.525 0 10.742-.743L4.25.751a.525.525 0 00-.743 0z" />
        <path d="M3.854.703a.525.525 0 00-.526.525v5.49a.525.525 0 001.05 0v-5.49a.525.525 0 00-.524-.525z" />
        <g>
          <path d="M4.201 15.541l2.817-2.816a.525.525 0 10-.743-.743L3.83 14.427l-2.445-2.445a.525.525 0 10-.743.743l2.817 2.816a.525.525 0 00.742 0z" />
          <path d="M3.854 15.59c.29 0 .525-.236.525-.526v-5.49a.525.525 0 00-1.05 0v5.49c0 .29.235.526.525.526z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
