export class ThemeCacher {
  constructor(storage, options = {}) {
    this.storage = storage
    this.defaultTheme = options.defaultTheme || 'light'
    this.allowedThemes = options.allowedThemes || ['light', 'dark']
  }

  get() {
    if (!this.storage) {
      return this.defaultTheme
    }

    const cached = this.storage.getItem('theme')
    if (cached && this.allowedThemes.indexOf(cached) !== -1) {
      return cached
    }

    return this.defaultTheme
  }

  set(theme) {
    this.storage.setItem('theme', theme)
  }
}

export const hexToRgb = (hex) => {
  const standard = hex.length === 4 ? hex + hex.substr(1) : hex
  return [
    parseInt(standard.substring(1, 3), 16),
    parseInt(standard.substring(3, 5), 16),
    parseInt(standard.substring(5, 7), 16),
  ]
}

export const hex2Rgba = (hex, alpha = 1) => {
  const [r, g, b] = hexToRgb(hex)
  return `rgba(${[r, g, b, alpha].join()})`
}

// Adapted from https://gist.github.com/mjackson/5311256
export const hexToHsl = (hex) => {
  let [r, g, b] = hexToRgb(hex).map((n) => n / 255)

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h
  let s
  let l = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    // eslint-disable-next-line default-case
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  return [h, s, l].map((n) => parseFloat(n.toFixed(3)))
}

/**
 * Get the last used theme from localStorage
 * @returns {string}
 */
export const getCachedTheme = () => {
  const storage = typeof window === 'undefined' ? null : window.localStorage
  const themeCache = new ThemeCacher(storage)
  return themeCache.get()
}

/**
 * Save the current theme to localStorage for saving preference
 * @param {string} theme - name of theme
 * @returns {string}
 */
export const setCachedTheme = (theme = 'light') => {
  const storage = typeof window === 'undefined' ? null : window.localStorage
  const themeCache = new ThemeCacher(storage)
  return themeCache.set(theme)
}

export const splitNumberFromString = (children) => {
  const isArray = Array.isArray(children)
  if (!children || (isArray && !children[0])) {
    return null
  }

  const contents = isArray ? children[0] : children
  if (typeof contents === 'string' || contents instanceof String) {
    const matches = contents.match(/(^\d*[a-zA-Z]?)\.\s{1}(.*)/)
    if (!matches) {
      return null
    }
    return {
      number: matches[1],
      text: matches[2],
    }
  }
  return null
}
