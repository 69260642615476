import {
  trackGoogleVirtualPageView,
  trackViewPageSectionEvent,
} from './src/utils/tracking'

// Remove older offline plugin service worker for this bug:
// https://typeform.atlassian.net/browse/PS-1431
function removeOfflineServiceWorker() {
  try {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((workers) => workers.map((worker) => worker.unregister()))
    }
  } catch (e) {
    console.error('Exception in onClientEntry Gastby hook:', e)
  }
}

export const onClientEntry = () => {
  removeOfflineServiceWorker()
}

let firstRender = true

export const onRouteUpdate = ({ location }) => {
  if (firstRender) {
    firstRender = false
  } else {
    // We're already tracking it once we have the consent
    trackViewPageSectionEvent()
  }
  trackGoogleVirtualPageView(location)
}
