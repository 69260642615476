import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import InViewport from 'react-visibility-sensor'

import Header from '../components/Header'
import Layout from '../components/Layout'
import Categories from '../components/Community/Categories'
import Contributors from '../components/Community/Contributors'
import BlocksIcon from '../components/icons/top-blocks'
import Card, {
  LinkTitle,
  Description,
  CTACard,
} from '../components/Community/Card'
import {
  Title as HeroTitle,
  Description as HeroDescription,
} from '../components/Hero'
import {
  ProjectsWrap,
  Column,
  Hero,
} from '../components/Community/visual-components'
import Footer from '../components/Footer'

const toCard = ({ title, description, url, contributors, categories }) => {
  const groupedContributors = contributors.reduce((result, contributor) => {
    if (!result[contributor.type]) {result[contributor.type] = []}
    result[contributor.type].push(contributor)
    return result
  }, {})

  return (
    <Card key={title}>
      <Categories categories={categories} />
      <LinkTitle href={url}>{title}</LinkTitle>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
      {Object.keys(groupedContributors).map((type) => (
        <Contributors
          title={type}
          people={groupedContributors[type]}
          key={type}
        />
      ))}
    </Card>
  )
}

class Community extends Component {
  state = {
    isHeaderPinned: false,
    isFloatingCardEnabled: false,
    isCTAVisible: true,
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    const minViableWidth = 1130
    this.setState({
      isFloatingCardEnabled: window.innerWidth >= minViableWidth,
    })
  }

  onChange = (isVisible) => {
    this.setState({ isCTAVisible: isVisible })
  }

  render() {
    const { pathname: currentPath } = this.props.location
    const {
      header: {
        frontmatter: { title, description },
      },
      cta,
      projects,
    } = this.props.data

    const cards = projects.edges
      .map((edge) => edge.node)
      .map((project) => {
        return {
          description: project.description,
          ...project.frontmatter,
        }
      })
      .map(toCard)

    return (
      <div>
        <Helmet title={`${title} - Typeform's Developer Platform`} />
        <CTACard
          mini
          isVisible={!this.state.isCTAVisible}
          {...cta.frontmatter}
        />
        <Layout>
          <Header currentPath={currentPath} />
          <Hero blocks={<BlocksIcon />}>
            <HeroTitle>{title}</HeroTitle>
            <HeroDescription>{description}</HeroDescription>
          </Hero>
          <ProjectsWrap>
            <Column>
              <InViewport
                onChange={this.onChange}
                active={this.state.isFloatingCardEnabled}
                minTopValue={100}
                partialVisibility
                resizeCheck
              >
                <CTACard {...cta.frontmatter} />
              </InViewport>
              {cards.filter((p, i) => i % 2)}
            </Column>
            <Column>{cards.filter((p, i) => i % 2 === 0)}</Column>
          </ProjectsWrap>
        </Layout>
        <Footer />
      </div>
    )
  }
}

export default Community

export const pageQuery = graphql`
  query CommunityQuery {
    header: markdownRemark(frontmatter: { type: { eq: "header" } }) {
      frontmatter {
        title
        description
      }
    }
    cta: markdownRemark(frontmatter: { type: { eq: "cta" } }) {
      frontmatter {
        title
        description
        button
        url
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "project" } } }
      sort: { frontmatter: { created_at: DESC } }
    ) {
      edges {
        node {
          frontmatter {
            title
            categories
            url
            created_at
            contributors {
              name
              type
              avatar
              description
              links {
                type
                url
              }
            }
          }
          description: html
        }
      }
    }
  }
`
