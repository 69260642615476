import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'medium'}</title>
      <path d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281z" />
      <path d="M11.288 12.011l.663-1.637c.434-1.073.867-2.147 1.305-3.219.025-.06.095-.145.145-.146.847-.009 1.695-.006 2.59-.006-.16.181-.277.353-.431.482-.371.308-.455.687-.448 1.16.027 1.807.008 3.614.015 5.421 0 .095.057.211.124.282.231.241.48.466.75.724h-3.835l.198-.196c.182-.172.372-.336.544-.517.065-.069.128-.176.128-.266.007-1.739.005-3.479-.024-5.232L10.456 15.2 7.681 9.081l-.038.004-.012.145c-.002 1.276-.006 2.552-.004 3.828 0 .274.077.525.253.747.285.358.557.727.834 1.091l.132.179H6.003l.069-.12c.291-.384.578-.772.877-1.15.175-.222.25-.471.25-.747-.001-1.477-.007-2.955-.002-4.432a1.06 1.06 0 00-.237-.692c-.233-.294-.458-.595-.705-.919l.21-.01c.781-.001 1.563.003 2.344-.004.137-.001.201.044.257.168.7 1.536 1.406 3.07 2.11 4.604l.115.239" />
    </svg>
  )
}

export default SvgComponent
