import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { hex2Rgba } from '../styles/utilities'
import { media } from '../styles/media'

const Wrapper = styled.h3`
  position: relative;
  margin: 0.6rem 0;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3;
  color: ${(props) => props.color};

  ${media.tablet`
    font-size: 1.125rem;
  `};
`

const Number = styled.span`
  position: absolute;
  height: 1.6rem;
  min-width: 1.6rem;
  transform: translateY(-50%);
  top: 50%;
  left: -2.5rem;
  font-size: 0.75rem;
  line-height: 1.6rem;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  border: 1px solid currentColor;
  background: ${(props) => hex2Rgba(props.color, 0.05)};
  color: ${(props) => props.color};

  ${media.tablet`
    display: inline-block;
    margin: 0 calc(100% - 1.6rem) 0.625rem 0;    
    position: static;
    height: 2.375rem;
    min-width: 2.375rem;
    transform: none;
    font-size: 1.125rem;
    line-height: 2.375rem;
  `};
`

const NumberedH3 = ({ color, children }) => (
  <Wrapper color={color}>
    <Number color={color}>{children.number}</Number>
    {children.text}
  </Wrapper>
)

NumberedH3.propTypes = {
  children: PropTypes.object,
  color: PropTypes.string,
}

export default NumberedH3
