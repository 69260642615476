import React from 'react'
import styled from 'styled-components'

import { media } from '../styles/media'

const Wrapper = styled.div`
  height: 100%;
  min-height: calc(100vh - 87px);
  background: #fff;
  transition: transform 0.25s ease;
  transform: ${(props) => (props.isMenuOpen ? 'translateX(-230px)' : 'none')};

  ${media.tabletLandscape`
   transform: ${(props) =>
     props.isSidebarOpen ? 'translateX(18rem)' : 'none'};
  `};
`

const Layout = ({ children, isSidebarOpen, closeSidebar }) => {
  const onClick = () => {
    isSidebarOpen && closeSidebar()
  }
  return (
    <Wrapper onClick={onClick} isSidebarOpen={isSidebarOpen}>
      {children}
    </Wrapper>
  )
}

export default Layout
