import isMobile from 'ismobilejs'
import trackingService from '@typeform/js-tracking'
import attributionUtil from '@typeform/js-tracking/lib/attributionUtil'
import idUtil from '@typeform/js-tracking/lib/idUtil'
import sessionUtil from '@typeform/js-tracking/lib/sessionUtil'

const SEGMENT_KEY =
  process.env.NODE_ENV === 'production'
    ? process.env.GATSBY_SEGMENT_KEY_PRODUCTION
    : process.env.GATSBY_SEGMENT_KEY_DEVELOPMENT

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || []
}

const segmentOptions = {
  integrations: {
    All: true,
    AutopilotHQ: true,
  },
}

function getCurrentPage() {
  const url = new URL(window.location.href)
  url.search = ''
  return url.toString()
}

export function trackRunInPostmanButton() {
  trackingService.trackItemClicked({
    category: 'public_site',
    item: 'run_in_postman',
  })
}

export function trackGoogleVirtualPageView(location) {
  window.dataLayer.push({
    event: 'VirtualPageview',
    virtualPageURL: location.pathname,
  })
}

export function trackViewPageSectionEvent() {
  const eventTime = new Date().toISOString()
  const category = 'public_site'
  const page = getCurrentPage()

  const properties = {
    sentAt: eventTime,
    category: category,
    page,
  }

  trackingService.trackViewPageSection(properties, segmentOptions)
}

export function trackSignUp(location) {
  trackingService.trackItemClicked({
    item: 'sign_up',
    item_type: 'button',
    label: 'Sign up',
    page: getCurrentPage(),
    link_url: '/signup',
    location, //'header_menu|main_menu'
    category: 'public_site',
  })
}

export function trackLogin(location) {
  trackingService.trackItemClicked({
    item: 'login',
    item_type: 'button',
    label: 'Login',
    page: getCurrentPage(),
    link_url: '/login',
    location, //string header| main_menu
    category: 'public_site',
  })
}

export function initialiseTrackingService() {
  const isMobileDevice = isMobile(window.navigator).any

  const mandatoryAttributes = {
    attribution_user_id: attributionUtil.getUser(),
    unique_pageview_id: idUtil(),
    unique_sectionview_id: idUtil(),
    tracking_session_id: sessionUtil.read(),
    typeform_property: 'developer_portal',
    typeform_version: isMobileDevice ? 'mobile' : 'v2',
  }

  const options = {
    integrations: {
      All: true,
    },
  }

  trackingService.init(SEGMENT_KEY, mandatoryAttributes, options)
}
