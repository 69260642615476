import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'codepen'}</title>
      <path d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281zm.373-15.124a.85.85 0 00-1.002.013L5.882 8.492a.966.966 0 00-.382.778v3.579c0 .318.15.615.4.791l4.563 3.204c.298.21.686.21.984 0l4.653-3.266c.25-.175.4-.472.4-.791V9.271a.962.962 0 00-.4-.79l-4.727-3.323zm3.476 3.877l-1.72 1.227-1.613-1.246-.01-2.451 3.343 2.47zm-4.51-2.468v2.398l-1.653 1.228-1.648-1.174 3.301-2.452zM6.586 10.2l1.067.759-1.067.793v-1.553zm3.781 5.113l-3.329-2.372 1.663-1.235 1.666 1.187v2.419zm-.632-4.374l1.078-.801 1.255.877-1.113.794-1.22-.87zm1.808 4.374v-2.42l1.592-1.133 1.714 1.197-3.306 2.356zm3.781-3.52l-1.13-.789 1.13-.805v1.594z" />
    </svg>
  )
}

export default SvgComponent
