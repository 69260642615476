import React from 'react'

import { productColors } from '../styles/variables'
import CreateIcon from '../icons/create'
import EmbedIcon from '../icons/embed'
import GetStartedIcon from '../icons/get-started'
import ResponsesIcon from '../icons/responses'
import WebhooksIcon from '../icons/webhooks'

const Links = [
  {
    title: 'Get Started',
    href: '/get-started/',
    category: 'get-started',
    icon: <GetStartedIcon />,
    color: productColors.getstarted,
  },
  {
    title: 'Create API',
    href: '/create/',
    category: 'create',
    icon: <CreateIcon />,
    color: productColors.create,
  },
  {
    title: 'Responses API',
    href: '/responses/',
    category: 'responses',
    icon: <ResponsesIcon />,
    color: productColors.responses,
  },
  {
    title: 'Webhooks API',
    href: '/webhooks/',
    category: 'webhooks',
    icon: <WebhooksIcon />,
    color: productColors.webhooks,
  },
  {
    title: 'Embed',
    href: '/embed/',
    category: 'embed',
    icon: <EmbedIcon />,
    color: productColors.embed,
  },
]

export default Links
