import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import Button from '@typeform/ginger/dist/components/button'

import { trackLogin, trackSignUp } from '../../utils/tracking'

import {
  CloseButton,
  Hair,
  Inner,
  Item,
  Link,
  LinkWrap,
  Menu,
  MenuButton,
  MenuList,
  Nav,
  SignupButton,
  Spacer,
  Submenu,
  Wrap,
  MenuFooterWrapper,
  SignupButtons,
  Logo,
  Title,
  LogoLink,
} from './styled-components'
import { HEADER_HOOKS } from './constants'

const isInBrowser = typeof window !== 'undefined'

const isMobileViewport = () => isInBrowser && window.innerWidth <= 1024

const disableDocumentScroll = () => {
  document.body.style.overflow = 'hidden'
  document.body.style.touchAction = 'none'
}

const enableDocumentScroll = () => {
  document.body.style.overflow = ''
  document.body.style.touchAction = ''
}

const DefaultLink = ({ href, label }) => <Link href={href}>{label}</Link>

const linkToNavItem = (
  { label, link, palette, children = [], isActive = false, ...rest },
  LinkElement = DefaultLink
) => {
  return (
    <Item key={label}>
      <LinkWrap isActive={isActive}>
        <LinkElement
          href={link}
          label={label}
          data-gtm-event="nav_element--main"
          onClick={() => {
            if (isMobileViewport()) {
              enableDocumentScroll()
            }
          }}
          {...rest}
        />
      </LinkWrap>
      {children.length ? (
        <Submenu>{children.map((c) => linkToNavItem(c, LinkElement))}</Submenu>
      ) : null}
    </Item>
  )
}

class Header extends Component {
  static defaultProps = {
    fontName: 'apercu',
    palette: Palette.Positive,
  }

  state = {
    isMobile: isMobileViewport(),
    isMenuOpen: false,
  }

  componentDidMount() {
    if (isInBrowser) {
      window.addEventListener('resize', this.onResize)
    }
  }

  componentWillUnmount() {
    if (isInBrowser) {
      window.removeEventListener('resize', this.onResize)
    }
  }

  openMenu = () => {
    disableDocumentScroll()
    this.setState({ isMenuOpen: true })
  }

  closeMenu = () => {
    enableDocumentScroll()
    this.setState({ isMenuOpen: false })
  }

  onResize = debounce(() => {
    const isMobile = isMobileViewport()
    this.setState({ isMobile })
    if (!isMobile && this.state.isMenuOpen) {
      this.closeMenu()
    }
  }, 100)

  render() {
    const { palette, renderLink, title, hooks = {} } = this.props
    const linksData = this.props.links
    const { isMobile, isMenuOpen } = this.state
    return (
      <Wrap {...this.props}>
        <Inner>
          {hooks[HEADER_HOOKS.BEFORE_LOGO]
            ? hooks[HEADER_HOOKS.BEFORE_LOGO]()
            : null}
          <LogoLink to="/">
            {!isMobile && <Logo />}
            <Title>{title}</Title>
          </LogoLink>
          {hooks[HEADER_HOOKS.AFTER_LOGO]
            ? hooks[HEADER_HOOKS.AFTER_LOGO]()
            : null}
          <Spacer />
          <Nav>
            <Menu open={isMenuOpen}>
              <Hair
                top="0"
                right="20%"
                width="131"
                height="288"
                viewBox="0 0 131 288"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-7.203-11.898c88.42 119.5 134.236 219.308 137.445 299.42"
                  stroke="#fff"
                  fill="none"
                  fillRule="evenodd"
                />
              </Hair>
              <Hair
                left="0"
                bottom="5vh"
                width="222"
                height="76"
                viewBox="0 0 222 76"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-54.44 39.4c104.2 14.2 196.14 11 275.85-9.63m-273.7 51.3C-21.23 91.84 34.5 65.1 114.9.84"
                  stroke="#fff"
                  fill="none"
                />
              </Hair>
              <MenuList palette={palette} role="navigation">
                {linksData.map((link) => linkToNavItem(link, renderLink))}
              </MenuList>
              <MenuFooterWrapper>
                <Button
                  href="https://www.typeform.com/login"
                  palette={Palette.Negative}
                  variant="secondary"
                  size="medium"
                  onClick={() => trackLogin('mainmenu')}
                  hasMinWidth={false}
                >
                  Login
                </Button>
                <Button
                  href="https://www.typeform.com/signup"
                  palette={Palette.Negative}
                  size="medium"
                  onClick={() => trackSignUp('mainmenu')}
                  hasMinWidth={false}
                >
                  Sign up
                </Button>
              </MenuFooterWrapper>
              <CloseButton onClick={this.closeMenu}>&times;</CloseButton>
            </Menu>
            <SignupButtons isMobile={isMobile}>
              <Button
                href="https://www.typeform.com/login"
                variant="secondary"
                size="medium"
                onClick={() => trackLogin('header')}
                hasMinWidth={false}
              >
                Login
              </Button>
              <SignupButton
                href="https://www.typeform.com/signup"
                size="medium"
                hasMinWidth={false}
                onClick={() => trackSignUp('header_menu')}
              >
                Sign up
              </SignupButton>
            </SignupButtons>
            <MenuButton
              onClick={this.openMenu}
              title="Open site navigation"
              aria-label="Open site navigation"
            >
              <svg
                width="32"
                height="22"
                viewBox="0 0 32 22"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#262627" fillRule="evenodd">
                  <path d="M0 0h32v2H0zM0 10h32v2H0zM0 20h32v2H0z" />
                </g>
              </svg>
            </MenuButton>
          </Nav>
        </Inner>
      </Wrap>
    )
  }
}

export default Header
