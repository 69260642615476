import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" {...props}>
      <title>{'Group 11'}</title>
      <g fill="none" fillRule="evenodd">
        <rect
          stroke="#73BEC8"
          strokeWidth={1.65}
          x={0.825}
          y={0.825}
          width={26.35}
          height={26.35}
          rx={1.375}
        />
        <path
          d="M10.484 14.05H17"
          stroke="#73BEC8"
          strokeWidth={1.65}
          strokeLinecap="square"
        />
        <path fill="#73BEC8" d="M19.833 14l-3 2.25v-4.5z" />
      </g>
    </svg>
  )
}

export default SvgComponent
