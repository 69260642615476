import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={100} height={100} viewBox="0 0 100 100" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M48 22h6v8h-6zm0 48h6v8h-6zM79 47v6h-8v-6zm-48 0v6h-8v-6zM72.92 67.678l-4.242 4.242-5.657-5.657 4.242-4.242zM38.98 33.737l-4.243 4.242-5.657-5.657 4.242-4.242zM33.322 71.92l-4.242-4.242 5.657-5.657 4.242 4.242zm33.941-33.94l-4.242-4.243 5.657-5.657 4.242 4.242z"
          fill="#89BC62"
        />
        <rect
          x={3}
          y={3}
          width={94}
          height={94}
          rx={5}
          strokeWidth={6}
          stroke="#89BC62"
        />
        <circle stroke="#89BC62" strokeWidth={6} cx={51} cy={50} r={11} />
      </g>
    </svg>
  )
}

export default SvgComponent
