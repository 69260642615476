import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { RouterButton } from '../../Button/index'
import { media } from '../../styles/media'
import { gridWidth, pink, monospace } from '../../styles/variables'
import { ensureTrailingSlashes, styliseCategory } from '../../../utils'

const Container = styled.div`
  position: relative;
  padding: 8rem 0;
  overflow: hidden;
  text-align: center;

  ${media.phone`
    padding: 5rem 0 3rem;
  `};
`

const Grid = styled.div`
  max-width: ${gridWidth};
  margin: 0 auto;
`

const Title = styled.h3`
  font-family: ${monospace};
  font-weight: bold;
  font-size: 1.75rem;
  letter-spacing: -0.6px;
  line-height: 1.285;
  margin: 0;

  ${media.phone`
    font-size: 1.5625rem;
  `};
`

const Pink = styled.span`
  color: ${pink};
`

const EndPointsLink = styled(Link)`
  display: inline-block;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &::after {
    content: '';
    display: block;
    opacity: 0;
    box-shadow: 0 1px 0 1px currentColor;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.4s ease;
  }

  &:hover::after {
    opacity: 1;
    transform: scaleX(1);
  }
`

const Buttons = styled.div`
  position: relative;
  max-width: 450px;
  margin: 3rem auto 0;
  z-index: 2;

  ${media.phone`
    margin: 1.5rem 0 0;
  `};
`

const buttons = [
  ['create', '#89bc62', '#c6dfb2'],
  ['responses', '#e4ba3f', '#f3bea1'],
  ['webhooks', '#c588c7', '#e2c3e3'],
  ['embed', '#cb732b', '#e6bb98'],
]

const References = () => {
  return (
    <Container>
      <Grid>
        <Title>
          <span>Looking for </span>
          <Pink>
            <EndPointsLink to="get-started">endpoints</EndPointsLink>?
          </Pink>
        </Title>
        <Buttons>
          {buttons.map(([category, color, border]) => (
            <RouterButton
              to={ensureTrailingSlashes(category)}
              key={category}
              color={color}
              borderColor={border}
              monospace
              style={{ margin: '1rem', width: 'calc(50% - 2rem)' }}
            >
              {styliseCategory(category)}
            </RouterButton>
          ))}
        </Buttons>
      </Grid>
    </Container>
  )
}

export default References
