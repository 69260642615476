import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={1133} height={553} viewBox="0 0 1133 553" {...props}>
      <title>{'top-blocks copy'}</title>
      <g fillRule="nonzero" fill="none">
        <rect
          fill="#73BEC8"
          x={386.599}
          y={386.741}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#F8FCFC"
          x={579.899}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#73BEC8"
          x={966.499}
          y={193.37}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#E3F2F4"
          x={579.899}
          y={386.741}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#73BEC8"
          x={773.199}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#B9DEE3"
          y={193.37}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#B9DEE3"
          x={579.899}
          y={193.37}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#F1F8F9"
          y={386.741}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#EAF6F7"
          x={193.3}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#EAF6F7"
          x={773.199}
          y={193.37}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#B9DEE3"
          x={193.3}
          y={386.741}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#B9DEE3"
          x={386.599}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#73BEC8"
          x={773.199}
          y={386.741}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#B9DEE3"
          x={966.499}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#B9DEE3"
          x={966.499}
          y={386.741}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect fill="#F8FCFC" width={165.685} height={165.746} rx={7} />
        <rect
          fill="#73BEC8"
          x={193.3}
          y={193.37}
          width={165.685}
          height={165.746}
          rx={7}
        />
        <rect
          fill="#EAF6F7"
          x={386.599}
          y={193.37}
          width={165.685}
          height={165.746}
          rx={7}
        />
      </g>
    </svg>
  )
}

export default SvgComponent
