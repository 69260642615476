import React from 'react'
import PropTypes from 'prop-types'
import { groupBy, toArray } from 'lodash'

import {
  SectionTitle,
  SubNavList,
  NavLink,
  NavGroup,
} from '../visual-components'
import { normalizePath } from '../../../utils'

const RefSection = ({ children, currentPath }) => {
  const path = normalizePath(currentPath)
  const groupedChildren = toArray(groupBy(children, (child) => child.category))
  return (
    <div>
      <SectionTitle>Reference</SectionTitle>
      <SubNavList>
        {groupedChildren.map((singleGroup) => (
          <NavGroup key={singleGroup.title}>
            {singleGroup.map(({ title, href, category }) => (
              <li key={href}>
                <NavLink to={href} active={path === normalizePath(href)}>
                  {title}
                </NavLink>
              </li>
            ))}
          </NavGroup>
        ))}
      </SubNavList>
    </div>
  )
}

RefSection.propTypes = {
  children: PropTypes.array,
  currentPath: PropTypes.string,
}

export default RefSection
