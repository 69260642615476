import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'facebook'}</title>
      <g fill="#0E4787">
        <path d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281z" />
        <path d="M12.942 6.925c-.744 0-.959.331-.959 1.058V9.19h1.984l-.198 1.951h-1.786v5.918h-2.38v-5.918H8V9.19h1.603V8.016c0-1.966.794-3.008 3.009-3.008.48 0 1.041.033 1.388.082v1.835" />
      </g>
    </svg>
  )
}

export default SvgComponent
