import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map } from 'lodash'

import SchemaProperty from '../SchemaProperty'
import { Row, ParameterValue } from '../SchemaProperty/visual-components'

const ChildTable = styled.div`
  padding: 0 1.25rem;
  background-color: ${(props) => (props.level % 2 ? '#f9f9f9' : '#fff')};
  margin-top: ${(props) => (props.level > 1 ? '1rem' : '0')};
  border-radius: 3px;
  border: 1px solid #ebebec;

  & ${Row} {
    border-bottom: 1px solid #ebebec;
    padding: 0.75rem 0;
  }

  & ${Row}:last-child {
    border-bottom: none;
  }

  & ${ParameterValue} {
    border-bottom: none;
    padding-bottom: 0;
  }
`

const willRenderChildTable = (schema) => {
  const { type, properties, items } = schema
  return (
    (type === 'object' && properties) ||
    (type === 'array' && Array.isArray(items)
      ? items[0].properties
      : items && items.properties)
  )
}

const SchemaTable = ({ name, schema, isRequired, level = 0, theme }) => {
  // base case, property is a scalar and can be rendered as a row
  if (!willRenderChildTable(schema)) {
    return (
      <SchemaProperty
        name={name}
        definition={schema}
        isRequired={isRequired}
        level={level}
        theme={theme}
      />
    )
  }

  // We are going to render a child table, the child table's
  // properties are in one of these two parameters
  if (schema.items && Array.isArray(schema.items)) {
    throw new Error(
      `schema.items has to be an object, not an array. schema: ${JSON.stringify(
        schema
      )}`
    )
  }
  const properties =
    (schema.items && schema.items.properties) || schema.properties
  const required = (schema.items && schema.items.required) || schema.required

  // When a child table is inside another child table, we should
  // add padding-bottom so the bottom borders do not overlap
  let hasBottomPadding = false
  const propertyNames = Object.keys(properties)
  if (propertyNames.length) {
    const lastPropertyName = propertyNames[propertyNames.length - 1]
    hasBottomPadding = willRenderChildTable(properties[lastPropertyName])
  }

  // Recursively render the properties of the property
  const children = map(properties, (schema, name) => {
    // TODO: not sure if setting the key this way is ok
    return (
      <SchemaTable
        key={`${name}/${schema.type}`}
        name={name}
        schema={schema}
        isRequired={required && required.includes(name)}
        level={level + 1}
        theme={theme}
      />
    )
  })

  return (
    <SchemaProperty
      name={name}
      definition={schema}
      level={level}
      isRequired={isRequired}
      theme={theme}
      expandable
    >
      <ChildTable level={level + 1} hasBottomPadding={hasBottomPadding}>
        {children}
      </ChildTable>
    </SchemaProperty>
  )
}

SchemaTable.propTypes = {
  name: PropTypes.string,
  schema: PropTypes.object, // a Schema Object from https://swagger.io/specification/
  isRequired: PropTypes.bool,
  level: PropTypes.number,
  theme: PropTypes.object,
}

export default SchemaTable
