import styled, { keyframes } from 'styled-components'

const blink = keyframes`
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }`

export default styled.span`
  display: inline-block;
  width: 4px;
  height: 2.4rem;
  margin: 0 0 -2px 5px;
  vertical-align: baseline;
  background: currentColor;
  animation: ${blink} 1s ease infinite;
`
