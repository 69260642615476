import React from 'react'
import MDReactComponent from 'markdown-react-js'
import Cui from 'react-cui'

import { splitNumberFromString } from '../styles/utilities'

import H1 from './H1'
import H2 from './H2'
import H3 from './H3'
import NumberedH3 from './NumberedH3'
import Link from './Link'
import Code from './Code'
import CodeBlock from './CodeBlock'
import PostmanButton from './PostmanButton'

import { Strong, Paragraph, Note, List, OrderedList, Image, Hr, Table } from '.'

const isNote = (children) => {
  if (typeof children[0] === 'object' && children[0].props.children) {
    const [ firstWord ] = children[0].props.children
    if (typeof firstWord === 'string') {
      return firstWord.toLowerCase().indexOf('note') !== -1
    }
  }
  return false
}

/* eslint-disable react/display-name */
const matchToComponent =
  ({ color, theme, images }) =>
  (Tag, props, children, level) => {
    switch (Tag) {
      case 'p': {
        const isNoteElem = isNote(children)
        return isNoteElem ? (
          <Note {...props} theme={theme}>
            {children}
          </Note>
        ) : (
          <Paragraph {...props} theme={theme}>
            {children}
          </Paragraph>
        )
      }
      case 'pre':
        return (
          <CodeBlock {...props} theme={theme}>
            {children}
          </CodeBlock>
        )
      case 'code':
        return (
          <Code {...props} color={color}>
            {children}
          </Code>
        )
      case 'img': {
        const externalImage = <Image {...props} />
        if (images && images.length) {
          const img = images.find((img) => img.title === props.alt)
          return img ? <Image src={img.src} alt={img.title} /> : externalImage
        }
        if (props.title && props.title.startsWith('customCSS=')) {
          // Example of image with custom CSS:
          // ![Alt text](https://images.com/imageUrl "customCSS=margin:1.5rem 0;width:100px;height:40px;")
          const customCSS = props.title.split('=').pop()

          return (
            <Image
              customCSS={customCSS}
              alt={props.alt}
              src={props.src}
              key={props.key}
            />
          )
        }
        return externalImage
      }
      case 'hr':
        return <Hr {...props} theme={theme} />
      case 'ol':
        return (
          <OrderedList {...props} theme={theme}>
            {children}
          </OrderedList>
        )
      case 'ul':
        return (
          <List {...props} theme={theme}>
            {children}
          </List>
        )
      case 'strong':
        return (
          <Strong {...props} theme={theme}>
            {children}
          </Strong>
        )
      case 'h1':
        return (
          <H1 {...props} color={color}>
            {children}
          </H1>
        )
      case 'h2':
        return (
          <H2 {...props} color={color} theme={theme}>
            {children}
          </H2>
        )
      case 'h3': {
        const numberedChildren = splitNumberFromString(children)
        return numberedChildren ? (
          <NumberedH3 {...props} color={color}>
            {numberedChildren}
          </NumberedH3>
        ) : (
          <H3 {...props} theme={theme}>
            {children}
          </H3>
        )
      }
      case 'a':
        if (
          Array.isArray(children) &&
          typeof children[0] === 'string' &&
          children[0].startsWith('CUI')
        ) {
          // Example of CUI in markdown:
          // [CUI](as87d6as "height=123px;avatar=https://image.com/image.jpg")
          const cuiId = props.href

          const cuiProps = props.title
            .split(';')
            .reduce((parsedProps, prop) => {
              const [key, value] = prop.split('=')
              parsedProps[key] = value

              return parsedProps
            }, {})

          return (
            <Cui
              uid={cuiId}
              theme={theme.color}
              height={cuiProps.height || '300px'}
              avatar={cuiProps.avatar}
            />
          )
        }

        if (
          Array.isArray(children) &&
          typeof children[0] === 'string' &&
          children[0].startsWith('postman')
        ) {
          return <PostmanButton {...props} />
        }

        return (
          <Link {...props} theme={theme} color={color}>
            {children}
          </Link>
        )
      case 'table':
        return (
          <Table {...props} theme={theme} level={level} cellspacing="0">
            {children}
          </Table>
        )
      default:
        return <Tag {...props}>{children}</Tag>
    }
  }

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ text, images, theme, color = theme.color }) => {
  const md = text.replace(/^-{3}([\s\S]*?)-{3}/, '').trim()
  const iterator = matchToComponent({ color, theme, images })
  return <MDReactComponent text={md} onIterate={iterator} />
}
