import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'github'}</title>
      <path
        d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281zm1.936-8.555c.214 0 .391.106.545.315.153.211.234.468.234.775 0 .306-.081.565-.234.774-.153.211-.332.315-.545.315-.221 0-.415-.104-.569-.315a1.277 1.277 0 01-.234-.774c0-.308.081-.565.234-.775.153-.21.347-.315.569-.315m2.735-2.83c.593.645.895 1.427.895 2.348 0 .595-.068 1.131-.206 1.609-.139.475-.313.862-.52 1.16-.21.299-.468.561-.774.786a3.928 3.928 0 01-.847.495 4.343 4.343 0 01-.883.243 7.429 7.429 0 01-.75.098l-.545.011-.435.012-.606.013-.606-.013-.435-.012-.545-.011a7.146 7.146 0 01-.75-.098 4.176 4.176 0 01-.883-.243 3.877 3.877 0 01-.846-.495 3.378 3.378 0 01-.775-.786c-.208-.298-.381-.685-.52-1.16a5.792 5.792 0 01-.206-1.609c0-.921.301-1.704.895-2.348-.065-.033-.068-.355-.006-.968.05-.613.18-1.178.394-1.695.743.079 1.662.5 2.76 1.257.371-.095.875-.146 1.524-.146.677 0 1.186.051 1.525.146.5-.338.978-.613 1.435-.822.464-.208.799-.331 1.009-.363l.315-.072a5.52 5.52 0 01.394 1.695c.06.611.058.934-.007.967m-4.647 6.218c1.342 0 2.349-.161 3.038-.483.68-.321 1.027-.984 1.027-1.985 0-.58-.217-1.065-.654-1.453a1.462 1.462 0 00-.786-.386c-.298-.049-.755-.049-1.368 0a18.62 18.62 0 01-1.258.072c-.306 0-.636-.014-1.049-.047a29.368 29.368 0 00-.972-.062 2.948 2.948 0 00-.762.086c-.274.065-.5.178-.678.338-.414.373-.628.856-.628 1.453 0 1 .339 1.663 1.018 1.985.676.322 1.68.483 3.023.483h.049M9.09 11.726c.213 0 .391.106.545.315.153.211.234.468.234.775 0 .306-.081.565-.234.774-.153.211-.332.315-.545.315-.222 0-.415-.104-.569-.315a1.277 1.277 0 01-.234-.774c0-.308.081-.565.234-.775.153-.21.347-.315.569-.315"
        fill="#272727"
      />
    </svg>
  )
}

export default SvgComponent
