import React from 'react'

import { productColors } from '../../styles/variables'
import CreateIcon from '../../icons/create'
import ResponsesIcon from '../../icons/responses'
import WebHookIcon from '../../icons/webhooks'
import EmbedIcon from '../../icons/embed'

const ApiData = [
  [
    'create',
    'Create API',
    `Create, retrieve, update, and delete your typeforms, themes, and images.`,
    productColors.create,
    // eslint-disable-next-line react/jsx-key
    <CreateIcon />,
  ],
  [
    'responses',
    'Responses API',
    `Retrieve your typeform submissions---on demand and programmatically.`,
    productColors.responses,
    // eslint-disable-next-line react/jsx-key
    <ResponsesIcon />,
  ],
  [
    'webhooks',
    'Webhooks API',
    `Automatically deliver your typeform's submissions to your URL or web app.`,
    productColors.webhooks,
    // eslint-disable-next-line react/jsx-key
    <WebHookIcon />,
  ],
  [
    'embed',
    'Embed SDK',
    `Seamlessly integrate your typeform by embedding it in your website or web app.`,
    productColors.embed,
    // eslint-disable-next-line react/jsx-key
    <EmbedIcon />,
  ],
]

export default ApiData
