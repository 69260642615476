import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={100} height={100} viewBox="0 0 100 100" {...props}>
      <g stroke="#C588C7" strokeWidth={6} fill="none" fillRule="evenodd">
        <rect x={3} y={3} width={94} height={94} rx={7} />
        <path d="M34.912 62.147l9.234-15.238.113-.192a11.16 11.16 0 01-5.376-9.55C38.883 31 43.882 26 50.05 26c6.167 0 11.166 5 11.166 11.167" />
        <path d="M65.377 61.092l-17.818-.053-.222.002a11.16 11.16 0 01-5.41 9.531c-5.284 3.181-12.145 1.476-15.326-3.808-3.18-5.284-1.475-12.145 3.809-15.326" />
        <path d="M49.3 35.288l8.448 15.689.108.194a11.16 11.16 0 0110.957.21c5.315 3.13 7.088 9.974 3.959 15.289-3.128 5.314-9.973 7.087-15.287 3.959" />
      </g>
    </svg>
  )
}

export default SvgComponent
