import { withPrefix } from 'gatsby'

export const splitNumberFromString = (children) => {
  const isArray = Array.isArray(children)
  if (isArray && !children[0]) {
    return null
  }

  const contents = isArray ? children[0] : children
  const matches = contents.match(/(^\d*[a-zA-Z]?)\.\s{1}(.*)/)
  if (!matches) {
    return null
  }

  return {
    number: matches[1],
    text: matches[2],
  }
}

export const getBaseDirectory = (filePath) => {
  const split = filePath.split('/')
  return split[split.length - 2]
}

const stripTrailingSlash = (s) => {
  return s.replace(/\/$/, '')
}

export const normalizePath = (path) => {
  const prefix = stripTrailingSlash(withPrefix(''))
  return stripTrailingSlash(path.replace(prefix, ''))
}

export const ensureTrailingSlashes = (path) => {
  if (path.charAt(0) === '/' && path.charAt(path.length - 1) === '/') {
    return path
  }
  return `/${path}/`.replace(/\/{2,}/g, '/')
}

export const styliseCategory = (category) => {
  switch (category) {
    case `create`:
      return `Create_API`
    case `responses`:
      return `Responses_API`
    case `webhooks`:
      return `Webhooks_API`
    case `embed`:
      return `Embed_SDK`
    default:
      return null
  }
}
