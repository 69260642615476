import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import cheerio from 'cheerio'

import Markdown from '../components/Markdown/renderer'
import Page from '../components/Page'
import { getBaseDirectory } from '../utils'

class GuidePage extends Component {
  render() {
    if (!this.props.data) {
      return null
    }

    const { content } = this.props.data
    const path = content.fileAbsolutePath
    const category = getBaseDirectory(path).replace(/-/g, '')

    // TODO: refactor to not parse html (make an alternative to remark plugin?)
    // as the template is not the place to be doing this; HTML will be sent to client
    const images = []
    const $ = cheerio.load(content.html)

    // `gatsby-remark-images` copies the image file to the public folder, then creates
    // a wrapper element within the rendered markdown which has a "blurred" image preview
    // for slower connections, similar to medium
    $('.gatsby-resp-image-wrapper').each((i, elem) => {
      const img = $(elem).find('img')
      return images.push({
        title: img.attr('alt'),
        src: img.attr('src'),
      })
    })

    return (
      <div>
        <Helmet title={content.frontmatter.title} />
        <Page category={category} {...this.props}>
          <Markdown text={content.internal.content} images={images} />
        </Page>
      </div>
    )
  }
}

export default GuidePage

export const pageQuery = graphql`
  query GuidePagesQuery($id: String!) {
    content: markdownRemark(id: { eq: $id }) {
      id
      fileAbsolutePath
      frontmatter {
        title
        nav_title
        layout
        nav_order
      }
      headings {
        value
        depth
      }
      internal {
        content
      }
      html
    }
  }
`
