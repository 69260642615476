import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { kebabCase } from 'lodash'
import { graphql } from 'gatsby'

import Markdown from '../components/Markdown/renderer'
import themes from '../components/styles/theme.json'
import Header from '../components/Header'
import Hero, { Title, Description } from '../components/Hero'
import Layout from '../components/Layout'
import Content from '../components/Content'
import { ScrollButton } from '../components/Button'
import { media } from '../components/styles/media'
import { darkGrey } from '../components/styles/variables'
import Footer from '../components/Footer'

const LinksWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${media.tablet`
    margin-top: 2rem;
    max-width: 22.5rem;
    
    & ${ScrollButton} {
      margin-bottom: 1rem;
    }
  `};
`

class Troubleshooting extends Component {
  state = {
    headerPinned: false,
  }

  render() {
    const { pathname: currentPath } = this.props.location
    const { internal, headings, frontmatter } = this.props.data.markdownRemark
    const theme = { color: darkGrey, ...themes.light }

    return (
      <div>
        <Helmet title={frontmatter.title}>
          <meta name="description" content={frontmatter.introduction} />
        </Helmet>
        <Layout>
          <Header currentPath={currentPath} />
          <Hero>
            <Title>{frontmatter.title}</Title>
            <Description>{frontmatter.introduction}</Description>
            <LinksWrap>
              {headings
                .map((heading) => heading.value)
                .map((link) => (
                  <ScrollButton
                    to={kebabCase(link)}
                    key={link}
                    offset={-50}
                    duration={500}
                    smooth
                    monospace
                    style={{ fontSize: '0.9375rem' }}
                  >
                    {link}
                  </ScrollButton>
                ))}
            </LinksWrap>
          </Hero>
          <Content centeredLayout>
            <Markdown text={internal.content} theme={theme} />
          </Content>
        </Layout>
        <Footer />
      </div>
    )
  }
}

Troubleshooting.propTypes = {
  isLoading: PropTypes.bool,
  __url: PropTypes.string,
  head: PropTypes.object.isRequired,
  rawBody: PropTypes.string,
}

export default Troubleshooting

export const pageQuery = graphql`
  query TroubleshootingQuery {
    markdownRemark(frontmatter: { layout: { eq: "Troubleshooting" } }) {
      internal {
        content
      }
      headings(depth: h2) {
        value
      }
      frontmatter {
        title
        nav_title
        introduction
      }
    }
  }
`
