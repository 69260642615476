import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Clipboard from 'react-copy-to-clipboard'
import styled, { css } from 'styled-components'

import { monospace } from '../styles/variables'
import { blinkingBackground } from '../styles/animations'

// todo: sticky position

const blinkAnimation = css`
  animation: ${(props) => props.blinkPeriod}ms
    ${(props) => blinkingBackground(props)} ease 2;
`

const ClipboardButton = styled.button`
  display: inline-block;
  width: 3.5rem;
  padding: 0;
  text-align: center;
  //for some reason font-size is not correct in rems here in chrome
  font: 600 10px/2.3 ${monospace};
  color: ${(props) => props.theme.codeBlockButton.color};
  background: none;
  border: 1px solid ${(props) => props.theme.codeBlockButton.border};
  box-shadow: ${(props) => props.theme.codeBlockButton.shadow};
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  transition: background 0.3s ease;
  ${(props) => (props.isActive ? blinkAnimation : 'animation: none;')}

  &:hover {
    background: ${(props) => props.theme.codeBlockButton.hover.background};
    border-color: ${(props) => props.theme.codeBlockButton.hover.border};
  }
`

class CopyButton extends Component {
  static propTypes = {
    snippet: PropTypes.string,
    resetPeriod: PropTypes.number,
    theme: PropTypes.object,
  }

  static defaultProps = {
    resetPeriod: 500,
  }

  state = {
    isActive: false,
  }

  onClick = () => {
    this.setState({ isActive: true })
    setTimeout(() => {
      this.setState({ isActive: false })
    }, this.props.resetPeriod)
  }

  render() {
    const { resetPeriod, snippet } = this.props
    const { isActive } = this.state

    return (
      <Clipboard text={snippet} onCopy={this.onClick}>
        <ClipboardButton blinkPeriod={resetPeriod} isActive={isActive}>
          {isActive ? 'Copied' : 'Copy'}
        </ClipboardButton>
      </Clipboard>
    )
  }
}

export default CopyButton
