import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'

import SchemaTable from '../SchemaTable'

const ResponseFields = ({ definition, theme }) => {
  const { name, schema } = definition
  const { required } = schema

  const parameters = map(schema.properties, (schema, key) => {
    if (typeof schema === 'object') {
      schema.name = key
    }
    return (
      <SchemaTable
        name={name}
        schema={schema}
        isRequired={required && required.includes(name)}
        theme={theme}
      />
    )
  })

  return <div>{parameters}</div>
}

ResponseFields.propTypes = {
  definition: PropTypes.object,
}

export default ResponseFields
