import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <g fill="#E7E7E7" fillRule="evenodd">
        <path d="M10.315 11.805a4.102 4.102 0 01-2.92-1.208.88.88 0 01-.26-.625.88.88 0 01.26-.626.88.88 0 01.625-.26.88.88 0 01.626.26c.445.445 1.038.69 1.67.69.63 0 1.223-.245 1.669-.69l3.546-3.548a2.342 2.342 0 00.688-1.669 2.344 2.344 0 00-2.357-2.357c-.633 0-1.225.244-1.669.688L9.481 5.172a.879.879 0 01-.626.26.88.88 0 01-.626-.26.878.878 0 01-.26-.626.88.88 0 01.26-.626l2.712-2.712A4.104 4.104 0 0113.863 0a4.1 4.1 0 012.92 1.208 4.103 4.103 0 011.207 2.921 4.1 4.1 0 01-1.207 2.921l-3.547 3.547a4.102 4.102 0 01-2.92 1.208" />
        <path d="M4.127 17.991a4.1 4.1 0 01-2.92-1.206A4.1 4.1 0 010 13.865c0-1.107.428-2.144 1.206-2.922l3.338-3.339a4.104 4.104 0 012.922-1.208c1.105 0 2.142.43 2.92 1.208a.886.886 0 01-1.251 1.252 2.344 2.344 0 00-1.67-.687c-.632 0-1.225.244-1.668.687l-3.339 3.339c-.92.92-.92 2.418 0 3.338a2.344 2.344 0 001.67.688c.632 0 1.225-.244 1.669-.688l2.295-2.295a.88.88 0 01.626-.26.88.88 0 01.625.26c.167.167.26.39.26.626a.88.88 0 01-.26.626l-2.295 2.295a4.1 4.1 0 01-2.921 1.206" />
      </g>
    </svg>
  )
}

export default SvgComponent
