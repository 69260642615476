import { slice, sortBy } from 'lodash'

import { ensureTrailingSlashes } from '../utils'

const getNode = (edge) => edge.node

const filterGuidePages = (edge) => {
  const isPartial = ['Homepage', 'Reference'].includes(
    edge.node.frontmatter.layout
  )
  const isProject =
    edge.node.fileAbsolutePath.split('/').indexOf('projects') !== -1
  const isIndexPage = edge.node.fileAbsolutePath.endsWith('index.md')
  return !isPartial && !isProject && !isIndexPage
}

export const getSidebarLinks = ({ guides, references }) => {
  const unsortedGuidePages = guides.edges.filter(filterGuidePages).map(getNode)
  const guidePages = sortBy(
    unsortedGuidePages,
    (node) => node.frontmatter.nav_order || 1
  )
  const referencePages = references.edges.map(getNode)

  const links = {}

  guidePages.forEach(
    ({ fileAbsolutePath, frontmatter: { nav_title, title } }) => {
      const splitPaths = fileAbsolutePath.split('/')
      const [category, name] = slice(splitPaths, splitPaths.length - 2)
      if (!links[category]) {links[category] = { guides: [], references: [] }}
      const urlName = name.indexOf('index') === -1 ? name.split('.')[0] : ''
      links[category].guides.push({
        title: nav_title || title,
        href: ensureTrailingSlashes([category, urlName].join('/')),
      })
    }
  )

  referencePages.forEach(
    ({
      api,
      category,
      pathname,
      title,
      operation: { summary, description },
    }) => {
      links[api].references.push({
        title: title || summary || description,
        href: ensureTrailingSlashes(pathname),
        category,
      })
    }
  )

  return links
}
