import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media } from '../styles/media'
import { hex2Rgba } from '../styles/utilities'
import InlineTag from '../InlineTag'

const Endpoint = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  padding: 0.35rem 0.8rem 0.35rem 0.35rem;
  line-height: 1.36;
  background: ${(props) => props.theme.references.endpoint.background};
  border: 1px solid ${(props) => props.theme.references.endpoint.border};
  transition: 0.15s ease;
  transition-property: background-color, border;
  border-radius: 2px;

  ${media.tablet`
    align-items: baseline;
    width: 100%
  `}
`

const Method = styled(InlineTag)`
  text-transform: uppercase;

  ${media.tablet`
    display:block;
  `}
`

const Hostname = styled.span`
  color: ${(props) => hex2Rgba(props.theme.references.endpoint.color, 0.5)};
  word-break: break-all;
`

const Path = styled.span`
  color: ${(props) => props.theme.references.endpoint.color};
  font-weight: 600;
  word-break: break-all;
`

const OperationEndpoint = ({ method, path }) => {
  return (
    <Endpoint>
      <Method>{method}</Method>
      <div>
        <Hostname>https://api.typeform.com</Hostname>
        <Path>{path}</Path>
      </div>
    </Endpoint>
  )
}

OperationEndpoint.propTypes = {
  method: PropTypes.string,
  path: PropTypes.string,
}

export default OperationEndpoint
