import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={12} height={6} viewBox="0 0 12 6" {...props}>
      <title>{'Path 3'}</title>
      <path
        d="M1.23 1.3l4.882 3.438L11.23 1.3"
        strokeWidth={2}
        stroke="#37404A"
        fill="none"
      />
    </svg>
  )
}

export default SvgComponent
