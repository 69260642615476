import React from 'react'
import styled, { css } from 'styled-components'

import { ExternalButton } from '../Button'
import { media } from '../styles/media'

export const Title = styled.span`
  font-size: 1.75rem;
  line-height: 1.15;
  font-weight: 700;
`

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 500;
  margin: 0.9375rem 0 1.5625rem;
`

const Link = styled.a`
  max-width: 90%;
  display: inline-block;
  transform: translateX(0);
  transition: transform 0.25s ease;

  :hover {
    text-decoration: none;
    transform: translateX(5px);
  }

  ::after {
    content: ' →';
    display: inline-block;
    font-size: 1.75rem;
    opacity: 0;
    margin-left: 10px;
    transition: opacity 0.25s ease;
  }

  :hover::after {
    opacity: 1;
  }
`
export const Card = styled.div`
  display: box;
  padding: 30px;
  margin-bottom: 45px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 20px 40px -5px rgba(0, 0, 0, 0.1);

  ${media.phone`
    padding: 30px 60px 30px 30px;
  `};
`

export const CTACardWrap = styled(Card)`
  background: #73bec8;
  color: #fff;
  border: none;
  transition: 0.15s ease;
  transition-property: opacity, transform;

  ${(props) =>
    props.mini &&
    css`
      position: fixed;
      width: 160px;
      padding: 15px;
      top: 20px;
      left: 20px;
      opacity: ${(props) => (props.isVisible ? '1' : '0')};
      pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};
      transform: translateY(${(props) => (props.isVisible ? '0' : '-20px')});
      z-index: 4;

      ${Title} {
        font-size: 1.125rem;
      }

      ${Description} {
        font-size: 0.9375rem;
      }

      ${ExternalButton} {
        font-size: 0.84375rem;
        width: 100%;
      }
    `};
`

export const LinkTitle = ({ href, children }) => {
  return (
    <Link href={href} target="blank">
      <Title>{children}</Title>
    </Link>
  )
}

export const CTACard = ({
  title,
  description,
  url,
  button,
  isVisible = true,
  mini,
}) => {
  return (
    <CTACardWrap isVisible={isVisible} mini={mini}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ExternalButton href={url} borderColor="fff" target="blank">
        {button}
      </ExternalButton>
    </CTACardWrap>
  )
}

export default Card
