import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'twitter'}</title>
      <path
        d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281zm5.5-12.937c-.185.11-.741.33-1.259.385.332-.193.824-.825.946-1.327-.32.22-1.053.54-1.416.54l.001.002a2.189 2.189 0 00-1.641-.756c-1.253 0-2.269 1.094-2.269 2.444 0 .187.021.369.058.545h-.001c-1.7-.047-3.687-.964-4.807-2.535-.688 1.283-.093 2.709.688 3.229-.267.022-.759-.033-.991-.275-.016.851.365 1.979 1.75 2.387-.267.155-.739.111-.945.077.072.72 1.007 1.66 2.028 1.66-.364.453-1.606 1.276-3.143 1.014a6.453 6.453 0 003.55 1.081c3.661 0 6.504-3.195 6.351-7.137l-.001-.013.001-.031-.001-.037c.333-.244.78-.678 1.1-1.25z"
        fill="#4099FF"
      />
    </svg>
  )
}

export default SvgComponent
