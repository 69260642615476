import React from 'react'
import PropTypes from 'prop-types'

import { NavList } from './visual-components'
import RefSection from './RefSection'
import GuideLinks from './GuideLinks'

const NavLinks = ({ currentPath, isActive, children }) => {
  return (
    <NavList isActiveSection={isActive}>
      <GuideLinks currentPath={currentPath}>{children.guides}</GuideLinks>
      {children.references.length ? (
        <RefSection currentPath={currentPath}>{children.references}</RefSection>
      ) : null}
    </NavList>
  )
}

NavLinks.propTypes = {
  currentPath: PropTypes.string,
  isActive: PropTypes.bool,
}

NavLinks.defaultProps = {
  isActive: false,
}

export default NavLinks
