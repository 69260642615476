import React from 'react'
import styled from 'styled-components'

import Popover from '../Popover'
import { Icon, social } from '../icons'

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`

const SectionTitle = styled.h4`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 2px;
  padding-bottom: 5px;
  border-bottom: 2px solid #f3f3f3;
`

export const Avatar = styled.img`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
`

const PopoverContainer = styled.div`
  width: 165px;
  padding: 20px 10px;
  line-height: 1.5;
`

const Name = styled.div`
  font-size: 0.8125rem;
  font-weight: 600;
`

const Description = styled.div`
  color: #a9a9a9;
  font-weight: 500;
  font-size: 0.6875rem;
`

const SocialLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
`

const Item = styled.li`
  display: inline-block;
  margin-right: 5px;
`

const SocialLink = styled.a`
  svg path {
    fill: #d5d5d5;
    transition: fill 0.25s ease;
  }

  :hover svg path {
    fill: inherit;
  }
`

const ContributorPopover = ({ name, avatar, description, links }) => {
  return (
    <PopoverContainer>
      <Avatar src={avatar} size="60px" />
      <Name>{name}</Name>
      <Description>{description}</Description>
      <SocialLinks>
        {links.map(({ type, url }) => (
          <Item key={type}>
            <SocialLink href={url} target="blank">
              <Icon size={22}>{social[type]}</Icon>
            </SocialLink>
          </Item>
        ))}
      </SocialLinks>
    </PopoverContainer>
  )
}

const Contributor = (props) => {
  return (
    <li key={props.name} style={{ marginRight: '12px' }}>
      <Popover content={<ContributorPopover {...props} />}>
        <Avatar src={props.avatar} size="40px" />
      </Popover>
    </li>
  )
}

const Contributors = ({ title, people }) => {
  return (
    <div>
      <SectionTitle>{title}s</SectionTitle>
      <List>{people.map(Contributor)}</List>
    </div>
  )
}

export default Contributors
