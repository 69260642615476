exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-troubleshooting-js": () => import("./../../../src/pages/troubleshooting.js" /* webpackChunkName: "component---src-pages-troubleshooting-js" */),
  "component---src-templates-guide-page-js": () => import("./../../../src/templates/GuidePage.js" /* webpackChunkName: "component---src-templates-guide-page-js" */),
  "component---src-templates-reference-page-js": () => import("./../../../src/templates/ReferencePage.js" /* webpackChunkName: "component---src-templates-reference-page-js" */)
}

