module.exports = `{
  "title": "Monty Python Quiz",
  "fields": [
    {
      "type": "short_text",
      "title": "What is the
        airspeed velocity of
        an unladen swallow?"
    }
  ]
}`
