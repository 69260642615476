import React, { Component } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  perspective: 400px;
`

const PopoverContent = styled.div`
  position: absolute;
  background: #fff;
  text-align: center;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  top: 0;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -105%) scale(0.9);
  transform-origin: bottom;
  transition: 0.25s ease;
  transition-property: opacity, transform;
  border-radius: 2px;
  pointer-events: none;
  z-index: 2;

  ${(props) =>
    props.open &&
    css`
      opacity: 1;
      pointer-events: auto;
      transform: translate(-50%, -105%);
      opacity: 1;
    `} &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #ffffff transparent transparent transparent;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
  }
`

export default class Popover extends Component {
  state = {
    hover: false,
  }

  onMouseOver = () => {
    this.setState({
      mouseOver: true,
      hover: true,
    })
  }

  onMouseLeave = () => {
    this.setState({ mouseOver: false })

    setTimeout(() => {
      if (!this.state.mouseOver) {
        this.setState({ hover: false })
      }
    }, 200)
  }

  render() {
    return (
      <Wrapper
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        name="wrapper"
      >
        {this.props.children}
        <PopoverContent open={this.state.hover} name="content">
          {this.props.content}
        </PopoverContent>
      </Wrapper>
    )
  }
}
