import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { sampleSize } from 'lodash'

import Typer from '../Typer'
import { media, largerThan } from '../../styles/media'
import {
  gridWidth,
  darkGrey,
  pink,
  lightGrey,
  teal,
} from '../../styles/variables'
import BlocksIcon from '../../icons/top-blocks'

const Container = styled.div`
  padding: 3rem 0 4rem;
  position: relative;
  overflow: hidden;

  ${media.tablet`
    padding: 6.4rem 1.6rem .1rem;
  `} ${media.phone`
    padding-top: 3rem;
  `};
`

const Blocks = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(-335deg) translate(31%, -86%);
  z-index: 0;

  ${media.tablet`
    transform: translate(56%, -55%) rotate(-335deg);
  `} ${media.phone`
    transform: translate(49%, -51%) rotate(-335deg) scale(0.5);
    opacity: 0.3;
  `};
`

const TextContainer = styled.div`
  max-width: ${gridWidth};
  margin: 0 auto;
  z-index: 1;
  position: relative;

  ${media.phone`
    max-width: 100%;
  `};
`

const Heading = styled.h1`
  max-width: 38rem;
  font-size: 2.8rem;
  margin-bottom: 1.2rem;
  color: ${darkGrey};
  letter-spacing: -1.5px;
  line-height: 1.333;
  font-weight: bold;
  white-space: nowrap;

  ${media.tablet`
    max-width: 36rem;
  `} ${media.phone`
    max-width: 18rem;
    font-size: 2.3rem;
    margin-top: 0;
    line-height: 1.21;
  `};
`

const Typing = styled(Typer)`
  display: inline-block;
  color: ${pink};

  ${media.phone`
    display: block;
  `};
`

const Br = styled.br`
  ${(props) =>
    props.desktopOnly &&
    css`
      ${media.phone`
      display: none;
    `};
    `} ${(props) =>
    props.mobileOnly &&
    css`
      ${largerThan.phone`
      display: none;
    `};
    `};
`

const Subheading = styled.div`
  font-size: 1.25rem;
  max-width: 36rem;
  line-height: 1.5;
  font-weight: 600;
  color: ${lightGrey};
  letter-spacing: -0.4px;

  ${media.phone`
    font-size: 1.063rem;
  `};
`

const Emphasise = styled.span`
  color: ${darkGrey};
`

const Arrow = styled.span`
  color: ${teal};

  &:before {
    content: ' → ';
    font-family: Lucida Grande, sans-serif;
  }
`

const GetStartedLink = styled(Link)`
  font-weight: bold;
  color: ${teal};
  border-bottom: 3px solid;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
`

const NUM_EXTRA_PERSONAS = 4
const personas = [
  `Marketers`,
  `Geeky CEOs`,
  `Jedi Masters`,
  `Researchers`,
  `Ops Managers`,
  `Time Lords`,
  `CRM Admins`,
  `Integrators`,
  `Digital Artists`,
  `Data Scientists`,
  `Browncoats`,
  `Casual Coders`,
  `Prototypers`,
  `UX Strategists`,
  `Tinkerers`,
]

const Intro = () => {
  const availablePersonas = [
    'Developers',
    ...sampleSize(personas, NUM_EXTRA_PERSONAS),
  ]

  return (
    <Container>
      <Blocks>
        <BlocksIcon />
      </Blocks>
      <TextContainer>
        <Heading>
          Conversational <Br mobileOnly />
          Data <Br desktopOnly />
          Collection <Typing values={availablePersonas} prefix="for" />
        </Heading>
        <Subheading>
          Welcome to <Emphasise>Typeform’s Developer Portal</Emphasise>, where
          you’ll find everything you need to interact with our platform.
          <Arrow />
          <GetStartedLink to="/get-started/">Get started</GetStartedLink>
        </Subheading>
      </TextContainer>
    </Container>
  )
}

export default Intro
