import styled from 'styled-components'

export const H3 = styled.h3`
  margin: 0.6rem 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
`

export default H3
