import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <title>{'dribbble'}</title>
      <path
        d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20.281c-5.126 0-9.281-4.155-9.281-9.281S5.874 1.719 11 1.719 20.281 5.874 20.281 11 16.126 20.281 11 20.281zm5.027-12.195a5.779 5.779 0 00-2.113-2.113A5.682 5.682 0 0011 5.194a5.68 5.68 0 00-2.914.779 5.771 5.771 0 00-2.113 2.113A5.682 5.682 0 005.194 11c0 1.053.26 2.025.779 2.914a5.779 5.779 0 002.113 2.113c.89.519 1.861.779 2.914.779a5.68 5.68 0 002.914-.779 5.779 5.779 0 002.113-2.113c.519-.89.779-1.861.779-2.914a5.678 5.678 0 00-.778-2.914zM11 6.041c1.25 0 2.341.413 3.273 1.24l-.094.132-.283.306c-.151.151-.316.295-.495.431s-.422.291-.73.465a6.97 6.97 0 01-.983.457 21.587 21.587 0 00-1.86-2.888A4.99 4.99 0 0111 6.04zM7.115 7.923a4.892 4.892 0 011.769-1.406c.625.832 1.24 1.784 1.844 2.857a17.945 17.945 0 01-4.581.605 4.895 4.895 0 01.968-2.056zm-.741 4.865A4.86 4.86 0 016.041 11l.008-.159c1.824 0 3.52-.234 5.088-.703.161.312.295.592.401.839l-.098.034-.099.026-.28.098a5.896 5.896 0 00-.752.363c-.31.172-.639.376-.986.612a6.761 6.761 0 00-1.077.941 6.38 6.38 0 00-.934 1.258 4.944 4.944 0 01-.937-1.523zM11 15.959c-1.179 0-2.233-.378-3.16-1.134l.113.083c.176-.388.436-.761.779-1.119s.673-.646.99-.866c.317-.219.663-.426 1.036-.62s.627-.317.763-.37l.325-.125.015-.008h.015c.494 1.295.847 2.55 1.058 3.765a4.959 4.959 0 01-1.935.393zm4.173-2.279a4.96 4.96 0 01-1.398 1.433 21.268 21.268 0 00-.968-3.545c1.003-.156 2.033-.083 3.092.219a4.894 4.894 0 01-.726 1.894zm.71-2.748l-.189-.034-.276-.045-.363-.049-.427-.042-.484-.03-.522-.011-.556.015a6.6 6.6 0 00-.563.053l-.057-.125-.049-.132-.333-.718a7.7 7.7 0 00.994-.488c.315-.184.567-.348.756-.491.189-.144.368-.296.537-.457l.329-.336.144-.185.008-.008a4.847 4.847 0 011.126 3.099l-.076-.015z"
        fill="#EA4C89"
      />
    </svg>
  )
}

export default SvgComponent
